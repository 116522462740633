
import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/v1/order-detail/`;
const API_URL_PAY_PAL = `${process.env.REACT_APP_SERVER_URL}/pay-pal/api/`;


class OrderService {

    findAllBill(searchOrder) {
      return axios.post(API_URL + 'find-all', searchOrder, { headers: authHeader() });
    }

    exportExelFile(json){
      return axios.post(API_URL + 'export-excel', json, { headers: authHeader() });
    }
    importExcelTracking(json){
      return axios.post(API_URL_PAY_PAL + 'orders/checking', json, { headers: authHeader() });
    }

    refundOrder(jsonRefund){
      return axios.post(API_URL + 'refund', jsonRefund, { headers: authHeader() });
    }
    
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new OrderService();
