// assets
import { DropboxOutlined, CodeSandboxOutlined , BankOutlined ,FacebookOutlined,TikTokOutlined ,ProductOutlined,UserAddOutlined,ImportOutlined  } from '@ant-design/icons';
// icons
const icons = {

    DropboxOutlined,
    CodeSandboxOutlined,
    BankOutlined,
    FacebookOutlined,
    TikTokOutlined,
    ProductOutlined,
    UserAddOutlined,
    ImportOutlined  
};

const userAdmin = JSON.parse(localStorage.getItem('userAdmin'));

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
const manager = {
    id: 'group-manger',
    title: 'Manager',
    type: 'group',
    children: [
        {
            id: 'account-manager',
            title: 'Account Manager',
            type: 'item',
            url: '/manager/account',
            icon: icons.UserAddOutlined,
            breadcrumbs: false
        },
        {
            id: 'product-manager',
            title: 'Product Manager',
            type: 'item',
            url: '/manager/product',
            icon: icons.ProductOutlined,
            breadcrumbs: false
        },

        {
            id: 'payout-seller-manager',
            title: 'Payout Seller Manager',
            type: 'item',
            url: '/manager/payout/seller',
            icon: icons.BankOutlined,
            breadcrumbs: false
        },
        {
            id: 'payout-sup-manager',
            title: 'Payout Sup Manager',
            type: 'item',
            url: '/manager/payout/sup',
            icon: icons.ImportOutlined ,
            breadcrumbs: false
        },
        {
            id: 'facebook-pixel-manager',
            title: 'Pixel Facebook Manager',
            type: 'item',
            url: '/manager/pixel-facebook',
            icon: icons.FacebookOutlined,
            breadcrumbs: false
        },
        {
            id: 'tiktok-pixel-manager',
            title: 'Pixel Tiktok Manager',
            type: 'item',
            url: '/manager/pixel-tiktok',
            icon: icons.TikTokOutlined,
            breadcrumbs: false
        },
    

    ]
};

if (userAdmin && userAdmin.roles && !userAdmin.roles.includes('ROLE_ADMIN')) {
    const filteredChildren = manager.children.filter(item => item.id !== 'requestProduct');
    manager.children = filteredChildren;
}

export default manager;
