import { Button, Col, Image, Row, Modal, Form, DatePicker, Input, Card, Typography as TypographyAntd, message as antdMessage, Select } from "../../../node_modules/antd/es/index";
import Table from "../../../node_modules/react-bootstrap/esm/Table";
import { Typography } from "../../../node_modules/@mui/material/index";
import { useEffect, useState } from "react";
import HashLoader from "../../../node_modules/react-spinners/HashLoader";
import DateFormatConverter from "components/fomartDateTime/DateFormatConverter";
import withdrawalSerivce from "service/withdrawal-serivce";
import sellerService from "service/seller-service";

import { DollarCircleOutlined, FileTextOutlined, LineChartOutlined, WarningOutlined } from '@ant-design/icons';
const { Title, Text } = TypographyAntd;
const { RangePicker } = DatePicker;
const { Option } = Select;

const PayoutSellerManager = () => {

    const [loading, setLoading] = useState(false)
    const [loadingHistory, setLoadingHistory] = useState(false)

    const [listPayout, setListPayout] = useState([]);
    const [listSeller, setListSeller] = useState([]);
    const [historyPayout, setHistoryPayout] = useState([]);

    const fomartDate = new DateFormatConverter();
    const [searchPayout, setSearchPayout] = useState({
        startDate: null,
        endDate: null,
        sellerId: null
    })


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [formWithdrawal] = Form.useForm();

    useEffect(() => {
        findAllListSaleBySeller();
        findAllSeller()
        findAllHistoryPayoutBySeller();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchPayout]);

    const findAllListSaleBySeller = () => {
        setLoading(true)
        withdrawalSerivce.getSaleBySeller(searchPayout)
            .then(res => {
                setListPayout(res.data.data)
                setLoading(false)
            }).catch(err => {
                console.log(err);
            })
    }

    const findAllHistoryPayoutBySeller = () => {
        const json = {
            sellerId: searchPayout.sellerId
        }
        withdrawalSerivce.findAllHistory(json)
            .then(res => {
                setHistoryPayout(res.data.data)
                setLoadingHistory(false)
            }).catch(err => {
                console.log(err);
            })
    }

    const handleRangePickerChange = (dates, dateStrings) => {
        if (dates) {
            setSearchPayout({
                ...searchPayout,
                startDate: dates[0].format('YYYY-MM-DDTHH:mm:ss'),
                endDate: dates[1].format('YYYY-MM-DDTHH:mm:ss')
            });
        } else {
            setSearchPayout({
                ...searchPayout,
                startDate: null,
                endDate: null
            });
        }

    };


    const findAllSeller = () => {
        sellerService.findAllSeller()
            .then(res => {
                setListSeller(res.data.data)
            }).catch(err => {
                console.log(err);

            })
    }

    const handleSellerTemplateSelect = (selectedId) => {

        setSearchPayout({
            ...searchPayout,
            sellerId: selectedId
        });

    };


    const handlePayoutClick = () => {
        formWithdrawal.setFieldsValue({
            allCost: listPayout?.totalAllCost ?? 0,
            amount: listPayout?.totalProfit ?? 0,
            profit: listPayout?.totalProfit ?? 0,
            revProduct: listPayout?.totalRevProduct ?? 0,
        });
        setIsModalVisible(true);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        formWithdrawal.resetFields();

    };



    const handleCreateWithdrawal = async (values) => {
        console.log(values);
        try {
            const jsonReq = {
                allCost: values.allCost,
                amount: values.amount,
                profit: values.profit,
                revProduct: values.revProduct,
                transactionId: values.transactionId,
                idSeller: searchPayout.sellerId
            }
            withdrawalSerivce.createHistory(jsonReq)
                .then(res => {
                    console.log(res);
                    antdMessage.success('Withdrawal created successfully!');
                    findAllHistoryPayoutBySeller()
                }).catch(err => {
                    antdMessage.error('Failed to create withdrawal. Please try again.');
                })
            setIsModalVisible(false);
            formWithdrawal.resetFields();
        } catch (error) {
            antdMessage.error('Failed to create withdrawal. Please try again.');
        }
    };
    return (
        <>
            <Col md={5} className="mb-3">
                <label style={{ display: "block", marginBottom: "8px", fontSize: "16px", fontFamily: "Roboto", fontWeight: "bold" }}>
                    Please select seller
                </label>
                <Select
                    size="large"
                    onChange={handleSellerTemplateSelect}
                    name="idSeller"
                    placeholder="Select seller"
                    style={{ width: "100%" }}
                    required // This ensures that the Select is marked as required in form submissions
                >
                    {listSeller.map(seller => (
                        <Option key={seller.idSeller} value={seller.idSeller}>
                            {seller.idSeller + ". " + seller.nameSeller}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Card>
                <Row gutter={[16, 2]}>
                    <Col md={24} className='mb-3'>
                        <Typography variant="h5">Payout seller manager</Typography>
                    </Col>
                    <Col md={7}>
                        <RangePicker size="large" style={{ width: '100%' }}
                            onChange={handleRangePickerChange} showTime />
                    </Col>

                    <Col md={24} className="table-custom-responsive">
                        <Table className="table-custom" responsive>
                            <thead style={{ background: "#f2f2f2", fontWeight: "bold" }}>
                                <tr>
                                    <th>Product Link</th>
                                    <th>Name Seller</th>
                                    <th>Total sale</th>
                                    <th>Cost Percent</th>
                                    <th>Sum Cost</th>
                                    <th>Profit</th>
                                    <th>Payout</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <tr>
                                            <td colSpan="10" style={{ textAlign: "center", verticalAlign: "middle", height: "50vh" }}>
                                                <div style={{ display: "inline-block" }}>
                                                    <HashLoader color="#5f99f8" loading={loadingHistory} />
                                                </div>
                                            </td>
                                        </tr>
                                    </tr>
                                ) : (
                                    (!listPayout || !listPayout.saleBySellerResponses || listPayout.saleBySellerResponses.length === 0) ? (
                                        <tr>
                                            <td colSpan="10" style={{ textAlign: "center" }}>
                                                <Text style={{ color: "red" }}>
                                                    <WarningOutlined style={{ marginRight: '8px' }} />
                                                    Please select date and seller to display information
                                                </Text>                                            </td>
                                        </tr>
                                    ) : (
                                        listPayout.saleBySellerResponses.map((saleSeller, index) => (
                                            <tr key={index}>
                                                <td> <a target="_blank"
                                                    href={process.env.REACT_APP_URL_PRODUCT + "/" + saleSeller.productId}
                                                    rel="noreferrer"> {process.env.REACT_APP_URL_PRODUCT + "/" + saleSeller.productId} </a></td>
                                                <td>{saleSeller.nameSeller}</td>
                                                <td>{parseFloat(saleSeller.totalSale).toFixed(2)}</td>
                                                <td>{saleSeller.costPercent} %</td>
                                                <td>{parseFloat(saleSeller.allCost).toFixed(2)}</td>
                                                <td>{parseFloat(saleSeller.profit).toFixed(2)}</td>
                                                <td>{parseFloat(saleSeller.payout).toFixed(2)}</td>
                                            </tr>
                                        ))
                                    )
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="center">
                    <Col span={8}>
                        <Card className="summary-card" bordered={false}>
                            <DollarCircleOutlined className="summary-icon" style={{ color: 'blue' }} />
                            <Title level={4}>Revenue</Title>
                            <Title level={3}>{listPayout?.totalRevProduct ? parseFloat(listPayout.totalRevProduct).toFixed(2) : '0.00'}</Title>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card className="summary-card" bordered={false}>
                            <FileTextOutlined className="summary-icon" style={{ color: 'yellow' }} />
                            <Title level={4}>Cost</Title>
                            <Title level={3}>{listPayout?.totalAllCost ? parseFloat(listPayout.totalAllCost).toFixed(2) : '0.00'}</Title>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card className="summary-card" bordered={false}>
                            <LineChartOutlined className="summary-icon" style={{ color: 'green' }} />
                            <Title level={4}>Profit</Title>
                            <Title level={3}>{listPayout?.totalProfit ? parseFloat(listPayout.totalProfit).toFixed(2) : '0.00'}</Title>
                        </Card>
                    </Col>
                </Row>
                {listPayout?.totalProfit && (
                    <Col style={{ textAlign: "end" }} className="mt-3">
                        <Button
                            type="primary"
                            icon={<DollarCircleOutlined />}
                            onClick={handlePayoutClick}
                            size="large"
                            style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            Payout
                        </Button>
                    </Col>
                )}


                <Modal
                    title="Create New Withdrawal"
                    visible={isModalVisible}
                    onCancel={handleModalCancel}
                    footer={null}
                >
                    <Form
                        form={formWithdrawal}
                        layout="vertical"
                        onFinish={handleCreateWithdrawal}
                    >

                        <Form.Item
                            name="allCost"
                            label="All cost"
                            rules={[{ required: true, message: 'Please input the all cost!' }]}
                        >
                            <Input readOnly />
                        </Form.Item>

                        <Form.Item
                            name="profit"
                            label="Profit"
                            rules={[{ required: true, message: 'Please input the profit!' }]}
                        >
                            <Input readOnly />
                        </Form.Item>
                        <Form.Item
                            name="revProduct"
                            label="Rev Product"
                            rules={[{ required: true, message: 'Please input the revenue product!' }]}
                        >
                            <Input readOnly />
                        </Form.Item>
                        <Form.Item
                            name="amount"
                            label="Amount payout"
                            rules={[{ required: true, message: 'Please input the amount payout!' }]}
                        >
                            <Input readOnly />
                        </Form.Item>
                        <Form.Item
                            name="transactionId"
                            label="Transaction ID"
                            rules={[{ required: true, message: 'Please input the transaction ID!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Create
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Card>
            <Card className='mt-3'>
                <Row>
                    <Col md={24} className='mb-3'>
                        <Typography variant="h5">Withdrawal History (All time)</Typography>
                    </Col>

                    <Col md={24}>
                        <Table className="table-custom" responsive>
                            <thead style={{ background: "#f2f2f2", fontWeight: "bold" }}>
                                <tr>
                                    <th>No</th>
                                    <th>Withdraw Date</th>
                                    <th>Transition Id</th>
                                    <th>Sum cost</th>
                                    <th>Profit</th>
                                    <th>Payout</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadingHistory ? (
                                    <tr>
                                        <td colSpan="10" style={{ textAlign: "center", verticalAlign: "middle", height: "50vh" }}>
                                            <div style={{ display: "inline-block" }}>
                                                <HashLoader color="#5f99f8" loading={loading} />
                                            </div>
                                        </td>
                                    </tr>
                                ) : !historyPayout || historyPayout.length === 0 ? (
                                    <tr>
                                        <td colSpan="10" style={{ textAlign: "center" }}>
                                            <Text style={{ color: "red" }}>
                                                <WarningOutlined style={{ marginRight: '8px' }} />
                                                Please select date to display information
                                            </Text>
                                        </td>
                                    </tr>
                                ) : (
                                    historyPayout.map((history, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{fomartDate.formatDateString(history.withdrawalDate) + " " + fomartDate.formatTimeString(history.withdrawalDate)}</td>
                                            <td>{history.transactionId}</td>
                                            <td>{history.allCost}</td>
                                            <td>{history.profit}</td>
                                            <td>{history.profit}</td>
                                            <td
                                                style={{
                                                    color: history.status === true ? 'green' : 'red',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {history.status === true ? 'Paid' : 'Unpaid'}
                                            </td>
                                        </tr>
                                    ))

                                )}
                            </tbody>
                        </Table>

                    </Col>
                </Row>
            </Card>

        </>
    );
}



export default PayoutSellerManager;
