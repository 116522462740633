// assets
import { DashboardOutlined,HomeOutlined,AreaChartOutlined,ShoppingCartOutlined,SolutionOutlined,FundViewOutlined} from '@ant-design/icons';
// icons
const icons = {
  DashboardOutlined,
  HomeOutlined,
  AreaChartOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
  FundViewOutlined,
  
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  // title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'home',
      title: 'Home',
      type: 'item',
      url: '/home',
      icon: icons.HomeOutlined,
      breadcrumbs: false
    },
    {
      id: 'dashboard',
      title: 'Store Performance',
      type: 'item',
      url: '/store-perfomance',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    },
    {
      id: 'rankSeller',
      title: 'Ranking Seller',
      type: 'item',
      url: '/ranking/seller',
      icon: icons.SolutionOutlined,
      breadcrumbs: false
    },
    {
      id: 'productPerfomance',
      title: 'Product Performance',
      type: 'item',
      url: '/product-perfomance',
      icon: icons.AreaChartOutlined,
      breadcrumbs: false
    },
    {
      id: 'allOrder',
      title: 'All order',
      type: 'item',
      url: '/all-order',
      icon: icons.ShoppingCartOutlined,
      breadcrumbs: false
    },
    {
      id: 'liveView',
      title: 'Live View',
      type: 'item',
      url: '/live-view',
      icon: icons.FundViewOutlined,
      breadcrumbs: false
    },
  ]
};

export default dashboard;
