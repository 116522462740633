// assets
import { BgColorsOutlined, MessageOutlined, IeOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';

// icons
const icons = {
    BgColorsOutlined,
    MessageOutlined,
    IeOutlined,
    SettingOutlined,
    UserOutlined
};
const userAdmin = JSON.parse(localStorage.getItem('userAdmin'));

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
const store = {
    id: 'group-store',
    title: 'Store',
    type: 'group',
    children: [
        {
            id: 'theme',
            title: 'Theme',
            type: 'item',
            url: '/store/theme',
            icon: icons.BgColorsOutlined,
            breadcrumbs: false
        },
        {
            id: 'payoutRequest',
            title: 'Payout Request',
            type: 'item',
            url: '/store/payout-request',
            icon: icons.MessageOutlined,
            breadcrumbs: false
        },
        {
            id: 'domain',
            title: 'Domain',
            type: 'item',
            url: '/store/domain',
            icon: icons.IeOutlined,
            breadcrumbs: false
        },
        {
            id: 'setting',
            title: 'Setting',
            type: 'item',
            url: '/setting',
            icon: icons.SettingOutlined,
            breadcrumbs: false
        }
    ],
};

// Kiểm tra nếu role là 'admin' thì loại bỏ menu 'Account Manager'
if (userAdmin && userAdmin.roles && !userAdmin.roles.includes('ROLE_ADMIN')) {
  // Nếu vai trò không phải là 'admin', loại bỏ menu 'Account Manager'
  const filteredChildren = store.children.filter(item => item.id !== 'account-manager');
  store.children = filteredChildren;
}

export default store;
