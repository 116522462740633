
import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_SERVER_URL}/seller/`;
class SellerSerivce {
    findAllSeller() {
      return axios.get(API_URL + 'find-all-seller', { headers: authHeader() });
    }

    rankingSeller() {
      return axios.get(API_URL + 'top-seller', { headers: authHeader() });
    }
   

}
// eslint-disable-next-line import/no-anonymous-default-export
export default new SellerSerivce();