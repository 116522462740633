
import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/v1/withdrawal-seller/`;
const API_URL_SUP = `${process.env.REACT_APP_SERVER_URL}/api/v1/sup-manager/`;

class WithdrawalService {
    getSaleBySeller(json) {
        return axios.post(API_URL + 'get-sale-by-seller', json, { headers: authHeader() });
    }

    createHistory(json) {
        return axios.post(API_URL + 'create-history-payout-seller', json, { headers: authHeader() });
    }

    findAllHistory(sellerId) {
        return axios.post(API_URL + 'find-all-history-payout-seller', sellerId, { headers: authHeader() });
    }

    getSaleBySup(json) {
        return axios.post(API_URL_SUP + 'get-history-payout-sup', json, { headers: authHeader() });
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new WithdrawalService();
