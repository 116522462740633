import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import AllOrder from 'pages/performance/AllOrder';
import Home from 'pages/performance/Home';
import ProductPerfomance from 'pages/performance/ProductPerformance';

//product
import MyProduct from 'pages/product/MyProduct';


import Setting from 'pages/setting/Setting';

import Domain from 'pages/store/Domain';
import PayoutRequest from 'pages/store/PayoutRequest';
import Theme from 'pages/store/Theme';
import CreateProduct from 'pages/product/CreateProduct';
// render - dashboard
import TopSeller from 'pages/performance/TopSeller';
import LiveView from 'pages/performance/LiveView';

//manager
import AccountManager from 'pages/manager/AccountManager';
import PayoutSellerManager from 'pages/manager/PayoutSellerManager';
import PayoutSupManager from 'pages/manager/PayoutSupManager';

import PixelFacebookManager from 'pages/manager/PixelFacebookManager';
import PixelTiktokManager from 'pages/manager/PixelTiktokManager';
import ProductManager from 'pages/manager/ProductManager';
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
// render - utilities





// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: '/',
      children: [
        {
          path: 'store-perfomance',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'ranking/seller',
      element: <TopSeller />
    },
    {
      path: 'all-order',
      element: <AllOrder />
    },
    {
      path: 'home',
      element: <Home />
    },
    {
      path: 'product-perfomance',
      element: <ProductPerfomance />
    },
    {
      path: 'live-view',
      element: <LiveView />
    },
    {
      path: 'product',
      children: [
        {
          path: 'my-product',
          element: <MyProduct />
        },
        {
          path: 'create-product',
          element: <CreateProduct />
        }
      ]
    },
    {
      path: 'store',
      children: [
        {
          path: 'domain',
          element: <Domain />
        },
        {
          path: 'payout-request',
          element: <PayoutRequest />
        },
        {
          path: 'theme',
          element: <Theme />
        }
      ]
    },
    {
      path: 'setting',
      element: <Setting />
    },
    {
      path: 'manager',
      children: [
        {
          path: 'account',
          element: <AccountManager />
        },
        {
          path: 'payout/seller',
          element: <PayoutSellerManager />
        },
        {
          path: 'payout/sup',
          element: <PayoutSupManager />
        },
        {
          path: 'pixel-facebook',
          element: <PixelFacebookManager />
        },
        {
          path: 'pixel-tiktok',
          element: <PixelTiktokManager />
        },
        {
          path: 'product',
          element: <ProductManager />
        },
     
      ]
    },

  ]
};

export default MainRoutes;
