import { Button, Col, Image, Row, Modal, Form, Input, message as antdMessage } from "../../../node_modules/antd/es/index";
import Table from "../../../node_modules/react-bootstrap/esm/Table";
import { Typography } from "../../../node_modules/@mui/material/index";
import { useEffect, useState } from "react";
import accountSerivce from "service/account-serivce";
import HashLoader from "../../../node_modules/react-spinners/HashLoader";
import { EditOutlined } from '@ant-design/icons';
import DateFormatConverter from "components/fomartDateTime/DateFormatConverter";
import "./payout.css"
const AccountManager = () => {

    const [loading, setLoading] = useState(false)
    const [listAccount, setListAccount] = useState([]);
    const [visible, setVisible] = useState(false);
    const [formCreate] = Form.useForm();
    const fomartDate = new DateFormatConverter();

    useEffect(() => {
        findAllAccount();
    }, []);

    const findAllAccount = () => {
        setLoading(true)
        accountSerivce.findAllAccount()
            .then(res => {
                setListAccount(res.data.data)
                setLoading(false)
            }).catch(err => {
                console.log(err);
            })
    }



    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        formCreate.resetFields();

        setVisible(false);
    };

    const handleSubmit = async () => {
        try {
            const values = await formCreate.validateFields();
            const json = {
                username: values.username,
                phoneNumber: values.phoneNumber,
                fullName: values.fullName,
                email: values.email,
                role: ["seller"],
                password: values.password
            }
            accountSerivce.singUpAccount(json)
                .then(res => {
                    antdMessage.success("Create seller success")
                    findAllAccount();
                }).catch(err => {
                    console.log(err);
                    antdMessage.error("Create fail")
                })
            setVisible(false);
        } catch (errorInfo) {
            console.log('Validate Failed:', errorInfo);
        }
    };

    return (
        <>
            <Row>

                <Col md={24} className='mb-3'>
                    <Typography variant="h5">Seller Manager</Typography>
                </Col>
                <Col md={24} style={{ textAlign: "end" }} >
                    <Button size="large" style={{ backgroundColor: "#4789f7", color: "white" }} onClick={showModal} >Create Seller Account </Button>
                </Col>
                <Col md={24} className="table-custom-responsive">
                    <Table className="table-custom" responsive>
                        <thead style={{ background: "#f2f2f2", fontWeight: "bold" }}>
                            <tr>
                                <th style={{ width: "10px" }}>SellerId </th>
                                <th style={{ width: "10px" }}>Username </th>
                                <th>Full Name</th>
                                <th>Avatar</th>
                                <th>Create Date</th>
                                <th>Phone Number</th>
                                <th>Active</th>
                                <th>Role</th>
                                <th>Acction</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? (
                                    <tr>
                                        <td colSpan="10" style={{ textAlign: "center", verticalAlign: "middle", height: "50vh" }}>
                                            <div style={{ display: "inline-block" }}>
                                                <HashLoader color="#5f99f8" loading={loading} />
                                            </div>
                                        </td>
                                    </tr>
                                ) : (

                                    listAccount.length === 0 || listAccount.sellerEntity === null ? (
                                        <tr>
                                            <td colSpan="10" style={{ textAlign: "center" }}><Image preview={false} width={300} src="https://store.vtctelecom.com.vn/Content/images/no-data.png"></Image></td>
                                        </tr>
                                    ) : (
                                        listAccount.map((account, index) => (
                                            <tr key={index} >
                                                <td > {account.sellerEntity?.idSeller}</td>
                                                <td > {account?.username}</td>
                                                <td>
                                                    <span style={{ fontWeight: "bold" }}>{account.sellerEntity?.nameSeller}</span> <br />
                                                    <span style={{ fontSize: "12px" }}>{account.email}</span>
                                                </td>
                                                <td><Image preview={false} width={50} src={account.imgLogo} /></td>
                                                <td>
                                                    <span style={{ fontWeight: "bold" }}>{fomartDate.formatDateString(account.sellerEntity?.createDate)}</span> <br />
                                                    <span style={{ fontSize: "12px" }}>{fomartDate.formatTimeString(account.sellerEntity?.createDate)}</span>
                                                </td>
                                                <td> {account.sellerEntity?.phoneNumber}</td>
                                                <td>
                                                    <span style={{
                                                        borderColor: account.active ? '#dbfae6' : 'gray',
                                                        borderWidth: '1px',
                                                        color: account.active ? 'black' : 'white',
                                                        backgroundColor: account.active ? '#dbfae6' : 'gray',
                                                        borderStyle: 'solid',
                                                        padding: '5px',
                                                        borderRadius: '10px', marginTop: "18px"
                                                    }}>
                                                        {account.active ? 'Active' : 'Inactive'}
                                                    </span>
                                                </td>
                                                <td> {account.roleEntities[0].name}</td>
                                                <td>
                                                    <Button><EditOutlined /></Button>
                                                </td>
                                            </tr>
                                        ))
                                    ))}
                        </tbody>
                    </Table>
                </Col>
            </Row >

            <Modal
                title="Create Seller Account"
                open={visible}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                ]}
            >
                <Form layout="vertical" form={formCreate} onFinish={handleSubmit}>
                    <Form.Item
                        name="username"
                        label="Username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="confirmPassword"
                        label="Confirm Password"
                        dependencies={['password']}
                        rules={[
                            { required: true, message: 'Please confirm your password!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            { required: true, message: 'Please input your email!' },
                            { type: 'email', message: 'Please enter a valid email address!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="fullName"
                        label="Full Name"
                        rules={[{ required: true, message: 'Please input your full name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="phoneNumber"
                        label="Phone Number"
                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default AccountManager;
