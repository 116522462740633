
import moment from 'moment'; // Đảm bảo bạn đã cài đặt và import moment
import { Component } from 'react';

class DateFormatConverter extends Component {
    formatDateString(dateString) {
        if (!dateString) return 'Ngày không hợp lệ';

        // Sử dụng moment để chuyển đổi định dạng
        return moment(dateString, 'YYYY-MM-DDTHH:mm:ss').format('DD-MM-YYYY');
    }

    formatTimeString(timeString) {
        if (!timeString) return 'Ngày không hợp lệ';

        // Sử dụng moment để chuyển đổi định dạng
        return moment(timeString, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm');
    }
}
export default DateFormatConverter;
