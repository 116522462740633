
import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api`;
class AccountService {
    findAllAccount(json) {
      return axios.post(API_URL + '/v1/account/find-all', json, { headers: authHeader() });
    }

    singUpAccount(json){
      return axios.post(API_URL + '/auth/singup', json, { headers: authHeader() });

    }

}
// eslint-disable-next-line import/no-anonymous-default-export
export default new AccountService();
 