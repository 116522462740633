import { useEffect, useRef } from "react";
import "./index.css";
import { Col, Typography } from "antd";
import Globe from 'globe.gl';
import BounceLoader from "react-spinners/BounceLoader";

const LiveView = () => {
    const globeRef = useRef(null);
    const globeInstanceRef = useRef(null);

    useEffect(() => {
        // Fetch countries data from REST Countries API
        const fetchCountriesData = async () => {
            try {
                const response = await fetch('https://restcountries.com/v3.1/all');
                const countries = await response.json();
                const formattedCountries = countries.map(country => ({
                    label: country.name.common,
                    lat: country.latlng[0],
                    lng: country.latlng[1]
                }));
                return formattedCountries;
            } catch (error) {
                return [];
            }
        };

        const initializeGlobe = async () => {
            const countries = await fetchCountriesData();

            globeInstanceRef.current = Globe()
                .globeImageUrl('//unpkg.com/three-globe/example/img/earth-blue-marble.jpg')
                .labelsData(countries)
                .labelSize(0.5)
                .labelText(d => d.label)
                .labelDotRadius(0.2)
                .labelColor(() => 'orange')
                .labelResolution(2);

            const globeContainer = globeRef.current;
            globeInstanceRef.current(globeContainer);

            // Handle hover event
            const handleHover = (event) => {
                const { clientX, clientY } = event;
                const { top, left, width, height } = globeContainer.getBoundingClientRect();
                const x = (clientX - left) / width;
                const y = (clientY - top) / height;

                const closestPoint = findClosestPoint(x, y);
                if (closestPoint) {
                    console.log('Hovered over point:', closestPoint);
                    // Handle hover logic
                }
            };

            globeContainer.addEventListener('mousemove', handleHover);

            return () => {
                globeContainer.removeEventListener('mousemove', handleHover);
            };
        };

        initializeGlobe();
    }, []);

    const findClosestPoint = (x, y) => {
        const { pointsData } = globeInstanceRef.current;
        const pointsArray = Object.values(pointsData);
        let closestPoint = null;
        let minDistSquared = Infinity;

        pointsArray.forEach(point => {
            const distSquared = (point.x - x) ** 2 + (point.y - y) ** 2;
            if (distSquared < minDistSquared) {
                minDistSquared = distSquared;
                closestPoint = point;
            }
        });

        return closestPoint;
    };

    return (
        <>
            <Col md={24} className='mb-3' style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Typography variant="h5" style={{ marginRight: '10px' }}>
                    <BounceLoader color="green" size={10} loading={true} />
                </Typography>
                <Typography variant="h5">Live preview</Typography>
            </Col>
            <Col md={24} className='mb-3'>
                <div ref={globeRef} />
            </Col>
        </>
    );
}

export default LiveView;
