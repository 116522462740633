import { Tabs, Card, Button, Input, Modal, message as antdMessage } from 'antd';
import { AutoComplete, Col, Image, Row, } from '../../../node_modules/antd/es/index';
import { Typography } from "../../../node_modules/@mui/material/index";
import { useEffect, useState } from 'react';
import settingService from 'service/setting.service';
import Icon, { DeleteOutlined, SearchOutlined, MinusCircleOutlined } from '@ant-design/icons';
import Table from '../../../node_modules/react-bootstrap/esm/Table';
import productService from 'service/product.service';
import { confirmAlert } from "react-confirm-alert"; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import "./setting.css"
const { TabPane } = Tabs;


const Setting = () => {

    const [facebookPixel, setFacebookPixel] = useState([])
    const [productPixel, setProductPixel] = useState([])

    const [pixelId, setPixelId] = useState('');
    const [searchProduct, setSearchProduct] = useState("");
    const [options, setOptions] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openModalCreate, setOpenModalCreate] = useState(false);
    const [shipping, setShipping] = useState(0)
    //shipping


    useEffect(() => {
        findAllpixel();
        getShipping();
    }, []);


    const findAllpixel = () => {
        settingService.findAllPixelFacebook({})
            .then(res => {
                setFacebookPixel(res.data.data)

            }).catch(err => {
                console.log(err);
            })
    }
    const showPixelProduct = (pixel) => {
        setProductPixel(pixel)
        setOpenModal(true)
    }

    const handleSearchView = (value) => {
        setSearchProduct(value);
        if (value) {
            findProductByName(value);
        } else {
            setOptions([]);
        }
    }


    const handleSelectView = (value, option) => {
        const product = JSON.parse(value);
        setProductPixel(prevState => {
            const productExists = prevState.products.some(selectedProduct => selectedProduct.idProduct === product.idProduct);
            if (!productExists) {
                const newProducts = [...prevState.products, product];
                return { ...prevState, products: newProducts };
            } else {
                antdMessage.warning("Product already exists");
                return prevState;
            }
        });
        setSearchProduct('');
    };

    const addProductToPixel = () => {
        if (productPixel.products.length === 0) {
            antdMessage.error("Product id not null")
            return;
        }
        const transformedData = {
            idPixel: productPixel.pixelId,
            idProducts: productPixel.products
                .filter(product => product.idProduct !== null) // Lọc bỏ sản phẩm có idProduct là null
                .map(product => product.idProduct) // Chỉ lấy idProduct
        };
        settingService.createFacebookPixel(transformedData)
            .then(res => {
                antdMessage.success("Create Facebook Pixel success")
                findAllpixel();
                setOpenModal(false)

            }).catch(err => {
                console.log(err);
                antdMessage.error("Create Facebook Pixel success")

            })
    }


    const showModalAddPixel = () => {
        setOpenModalCreate(true)
    }
    const findProductByName = (value) => {
        const jsonSearch = {
            nameProduct: value
        }
        productService.findProductByName(jsonSearch)
            .then(res => {
                if (res.data.ecode === "200") {
                    setOptions(
                        res.data.data.map((product) => ({
                            value: JSON.stringify({ idProduct: product.idProduct, nameProduct: product.nameProduct, active: product.active, image: product.image }),
                            label: (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {product.nameProduct}
                                    <img src={product.image} alt={product.nameProduct} style={{ width: 50, height: 50 }} />

                                </div>
                            ),
                        }))
                    );
                }
            }).catch(err => {
                console.log(err);
            })
    }

    const handleSearch = (value) => {
        setSearchProduct(value);
        if (value) {
            findProductByName(value);
        } else {
            setOptions([]);
        }
    };

    const handleSelect = (value, option) => {
        const product = JSON.parse(value);

        // Kiểm tra xem selectedProducts có giá trị không
        if (selectedProducts) {
            const productExists = selectedProducts.some(selectedProduct => selectedProduct.idProduct === product.idProduct);

            if (!productExists) {
                setSelectedProducts(prevSelectedProducts => [...prevSelectedProducts, product]);
            } else {
                antdMessage.warning("Product already exists");
            }
        } else {
            setSelectedProducts(prevSelectedProducts => [...prevSelectedProducts, product]);
            console.error('selectedProducts is null or undefined.');
        }

        setSearchProduct('');
    };


    const addProductPixel = () => {
        if (pixelId === "") {
            antdMessage.error("Pixel id not null")
            return;
        } if (selectedProducts.length === 0) {
            antdMessage.error("Product id not null")
            return;
        }
        const jsonPayload = {
            idPixel: pixelId,
            idProducts: selectedProducts.map(product => product.idProduct)
        }
        settingService.createFacebookPixel(jsonPayload)
            .then(res => {
                antdMessage.success("Create Facebook Pixel success")
                findAllpixel();
                setPixelId("")
                setSelectedProducts(null)
                setOpenModalCreate(false)
                setSelectedProducts("")

            }).catch(err => {
                console.log(err);
                antdMessage.error("Create Facebook Pixel success")

            })
    }

    const handlePixelIdChange = (e) => {
        setPixelId(e.target.value);
    };


    const handleDeleteProduct = (productId) => {
        setSelectedProducts(selectedProducts.filter(product => product.id !== productId));
    };


    const confirmDelete = (idPixel) => {
        if (idPixel) {
            confirmAlert({
                message:
                    `Are you sure you want to delete pixel :` + idPixel + `?`,
                buttons: [
                    {
                        label: "Return",
                        className: "stayPage",
                    },
                    {
                        label: "Confirm",
                        onClick: () => deletePixel(idPixel),
                        className: "leavePage",
                    },
                ],
            });
        }
    };

    const deletePixel = (pixelId) => {
        const json = {
            idPixel: pixelId
        }
        settingService.deleteFacebookPixcel(json)
            .then(res => {
                antdMessage.success("Delete pixel success")
                findProductByName()
            }).catch(err => {
                console.log(err);
                antdMessage.error("Delete fail");
            })
    }

    //shipping
    const getShipping = () => {
        productService.findShippingFee()
            .then(res => {
                setShipping(res.data.data.fee)
            }).catch(err => {
                console.log(err);
            })
    }


    const handleChangeShippingFee = (value) => {
        setShipping(value);
    }

    const updateShippingFee = () => {
        productService.updateShippingFee(shipping)
            .then(res => {
                antdMessage.success("Update shipping fee success")
            }).catch(err => {
                console.log(err);
                antdMessage.errror("Update shipping fee faild")
            })
    }



    const deleteOneProductPixcel = (pixelId, productId) => {
        const json = {
            idPixel: pixelId,
            idProduct: productId
        }

        settingService.deleteFacebookPixcelByIdPro(json)
            .then(res => {
                antdMessage.success("Delete pixel success")
            }).catch(err => {
                console.log(err);
                antdMessage.error("Delete fail");
            })
    }


    return (
        <>
            <Col md={24} className='mb-3'>
                <Typography variant="h5">Setting</Typography>
            </Col>
            <div
                style={{
                    margin: 20,
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    width: "100%",
                    height: "1000px",
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                }}
            >
                <Tabs tabPosition="left" defaultActiveKey="1" style={{ margin: 20 }}>
                    <TabPane tab="General" key="1">
                        General
                    </TabPane>
                    <TabPane tab="Account" key="2">
                        Account
                    </TabPane>
                    <TabPane tab="Facebook Pixel" style={{ marginTop: "20px" }} key="3">
                        <Row>
                            <Col md={6}>
                                <span style={{ fontSize: "18px", fontWeight: "bold" }}>Facebook fixel</span>
                            </Col>
                            <Col md={18}>
                                {facebookPixel.map((fbpixel, index) => (
                                    <Row key={index}>
                                        <Col md={24}>
                                            <span style={{ fontSize: "13px", color: "#8e9bb7" }}>
                                                Pixel id
                                            </span>
                                        </Col>
                                        <Col md={6}>
                                            <span style={{ fontSize: "17px" }}>
                                                {fbpixel.pixelId}
                                            </span>
                                        </Col>
                                        <Col md={6} onClick={() => showPixelProduct(fbpixel)}>
                                            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                                                View  {fbpixel.products.length} Track product
                                            </span>
                                        </Col>
                                        <Col md={6} style={{ textAlign: "end", color: "red" }} className="mb-3"  >
                                            <DeleteOutlined onClick={() => confirmDelete(fbpixel.pixelId)} />
                                        </Col>
                                    </Row>
                                ))}
                                <Col md={24}>
                                    <Button type="primary" size="large" onClick={showModalAddPixel}>Add pixel ID</Button>
                                </Col>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Shipping" key="4" style={{ marginTop: "20px" }} >
                        <Row>
                            <Col md={6}>
                                <span style={{ fontSize: "18px", fontWeight: "bold" }}>Shipping</span>
                            </Col>

                            <Col md={18}>
                                <Row >
                                    <Col md={24}>
                                        <span style={{ fontSize: "18px", color: "#8e9bb7" }}>

                                            For First item

                                        </span>
                                    </Col>
                                    <Col md={12} className="mb-3">
                                        <Input size="large" value={shipping}
                                            onChange={(e) => handleChangeShippingFee(e.target.value)}
                                            addonAfter="USD" defaultValue="mysite" type="number" />
                                    </Col>

                                </Row>
                                <Col md={24} style={{ textAlign: "center" }}>
                                    <Button type="primary" size="large" onClick={updateShippingFee}>Save Change</Button>
                                </Col>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Policy" key="5">
                        Policy
                    </TabPane>
                </Tabs>
            </div>

            <Modal
                title="View tracked Product"
                centered
                open={openModal}
                onOk={() => addProductToPixel()}
                onCancel={() => setOpenModal(false)}
                width={800}
            >
                <Row>
                    <Col md={2}>
                        <span style={{ fontSize: "15px", color: "#8e9bb7" }}>
                            Pixel id:
                        </span>
                    </Col>
                    <Col md={18}>
                        <span style={{ fontSize: "15px" }}>
                            {productPixel.pixelId}
                        </span>
                    </Col>
                    <Col md={24} >
                        <div style={{ maxHeight: "400px", overflowY: "auto" }}>

                            <Table className="table-custom" responsive  >
                                <thead>
                                    <tr>
                                        <th colSpan="4">
                                            <AutoComplete
                                                options={options}
                                                style={{ width: "100%" }}
                                                onSearch={handleSearchView}
                                                placeholder="Search product"
                                                onSelect={handleSelectView}
                                                value={searchProduct}
                                            />                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productPixel.products?.map((product, index) => (
                                        <tr key={index}>
                                            <td ><DeleteOutlined style={{ color: "red", fontSize: "15px", marginTop: "18px" }} onClick={() => deleteOneProductPixcel(product.pixelIs, product.idProduct)} /></td>
                                            <td>
                                                <Image src={product.imageUrl || product.image} width="50px" alt={product.nameProduct} />
                                            </td>
                                            <td>
                                                <span style={{ fontSize: "15px" }}>
                                                    {product.nameProduct}
                                                </span>
                                            </td>
                                            <td>
                                                <span style={{
                                                    borderColor: product.active ? '#dbfae6' : 'gray',
                                                    borderWidth: '1px',
                                                    color: product.active ? 'black' : 'white',
                                                    backgroundColor: product.active ? '#dbfae6' : 'gray',
                                                    borderStyle: 'solid',
                                                    padding: '5px',
                                                    borderRadius: '10px'
                                                    , marginTop: "18px"
                                                }}>
                                                    {product.active ? 'Active' : 'Inactive'}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>

                    </Col>
                </Row>
            </Modal>

            <Modal
                title="Add Facebook pixel"
                centered
                open={openModalCreate}
                onOk={() => addProductPixel()}
                onCancel={() => setOpenModalCreate(false)}
                width={800}            >
                <Row>
                    <Col md={24}>
                        <span style={{ fontSize: "15px", color: "#8e9bb7" }}>
                            Pixel id:
                        </span>
                    </Col>
                    <Col md={18}>
                        <Input
                            size="large"
                            value={pixelId}
                            onChange={handlePixelIdChange}
                            placeholder="Paste pixel ID" />
                    </Col>
                    <Col md={24}>
                        <div style={{ maxHeight: "400px", overflowY: "auto" }}>

                            <Table className="table-custom" responsive >
                                <thead>
                                    <tr>
                                        <th colSpan="4">
                                            <AutoComplete
                                                options={options}
                                                style={{ width: "100%" }}
                                                onSearch={handleSearch}
                                                placeholder="Search product"
                                                onSelect={handleSelect}
                                                value={searchProduct}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedProducts?.length > 0 ? (
                                        selectedProducts.map((product, index) => (
                                            <tr key={index}>
                                                <td><DeleteOutlined style={{ color: "red", fontSize: "15px", marginTop: "18px" }} onClick={() => handleDeleteProduct(product.id)} /></td>
                                                <td>
                                                    <Image src={product.image} width="50px" alt={product.nameProduct} />
                                                </td>
                                                <td>
                                                    <span style={{ fontSize: "15px" }}>
                                                        {product.nameProduct}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span style={{
                                                        borderColor: product.active ? '#dbfae6' : 'gray',
                                                        borderWidth: '1px',
                                                        color: product.active ? 'black' : 'white',
                                                        backgroundColor: product.active ? '#dbfae6' : 'gray',
                                                        borderStyle: 'solid',
                                                        padding: '5px',
                                                        borderRadius: '10px', marginTop: "18px"
                                                    }}>
                                                        {product.active ? 'Active' : 'Inactive'}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4" style={{ textAlign: "center", padding: "20px" }}>
                                                Search & Add product
                                                to Apply Pixel.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Modal>

        </>
    )
}
export default Setting;
