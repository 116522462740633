import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/v1/facebook-pixel/`;
class FacebookPixelSerivce {
    findAllPixelFacebook(searchOrder) {
      return axios.post(API_URL + 'find-all', searchOrder, { headers: authHeader() });
    }

    findAllBySeller(searchOrder) {
      return axios.post(API_URL + 'find-all-by-sellerId', searchOrder, { headers: authHeader() });
    }
    createFacebookPixel(json){
        return axios.post(API_URL + 'create', json, { headers: authHeader() });
    }

    deleteFacebookPixcel(json){
      return axios.post(API_URL + 'delete', json, { headers: authHeader() });
    }

    deleteFacebookPixcelByIdPro(json){
      return axios.post(API_URL + 'delete/one-product', json, { headers: authHeader() });
    }


   


}
// eslint-disable-next-line import/no-anonymous-default-export
export default new FacebookPixelSerivce();
