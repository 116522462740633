import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function useAuthRedirect() {
    const navigate = useNavigate();

    useEffect(() => {
        // Kiểm tra xem localStorage có chứa thông tin userAdmin hay không
        const userAdmin = localStorage.getItem('userAdmin');
        if (!userAdmin) {
            // Nếu không, chuyển hướng đến trang đăng nhập
            navigate('/login');
        }
    }, [navigate]); // Đảm bảo hook chỉ được gọi một lần khi component được mount
}

export default useAuthRedirect;