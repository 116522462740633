// project import
import dashboard from './dashboard';
import product from './product';
import store from './store';
import manager from './manager';
// ==============================|| MENU ITEMS ||============================== //
const userAdmin = JSON.parse(localStorage.getItem('userAdmin'));
const userSeller = JSON.parse(localStorage.getItem('ROLE_SELLER'));


const hasRole = (user, role) => {
  return user && user.roles && user.roles.includes(role);
};

const getMenuItems = (user) => {
  const items = [dashboard, product, store, manager];

  if (hasRole(user, 'ROLE_ADMIN')) {
    return items.filter(item => item !== store && item !== product);
  }
  if (hasRole(user, 'ROLE_SELLER')) {
    return items.filter(item => item !== manager);
  }

  return items;
};

// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
  items: getMenuItems(userAdmin || userSeller) // Kết hợp cả người dùng quản trị viên và người bán
};

export default menuItems;
