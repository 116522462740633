import { useEffect, useState } from 'react';
import {
    Grid,
    Typography
} from '@mui/material';
import Table from "../../../node_modules/react-bootstrap/esm/Table";
import productService from 'service/product.service';
import { useNavigate } from "../../../node_modules/react-router-dom/dist/index";
import { Col, Row } from '../../../node_modules/antd/es/index';
import {  DatePicker, Button, message as antdMessage } from 'antd';
import { format } from 'date-fns';
import HashLoader from '../../../node_modules/react-spinners/HashLoader';
import "./index.css"
import dayjs from 'dayjs';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const ProductPerfomance = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const [listAnalytics, setListAnalytics] = useState([])
    const [requestAnalytics, setRequestAnalytics] = useState({
        startDate: null,
        endDate: null
    })


    useEffect(() => {
        getAnalytics();
    }, [requestAnalytics.endDate, requestAnalytics.startDate]);

    const getAnalytics = () => {
        setLoading(true)
        productService.productAnalytics(requestAnalytics)
            .then(res => {
                setListAnalytics(res.data.data)
                setLoading(false)

            }).catch(err => {
                console.log(err);
                if (err.response.status === 401) {
                    navigate("/login")
                }
            })
    }

    const handleRangePickerChange = (dates) => {
        if (dates && dates.length === 2) {
            const startDate = dates[0].format('YYYY-MM-DDT00:00:00');
            const endDate = dates[1].format('YYYY-MM-DDT23:59:59');
            setRequestAnalytics({
                startDate,
                endDate,
            });
        }
    };



    const rangePresets = [
        {
            label: 'Today',
            value: [dayjs().add(0, 'd'), dayjs()],
        },
        {
            label: 'Yesterday',
            value: [dayjs().add(-1, 'd'), dayjs()],
        },
        {
            label: 'Last 7 Days',
            value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
            label: 'Last 14 Days',
            value: [dayjs().add(-14, 'd'), dayjs()],
        },
        {
            label: 'Last 30 Days',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
            label: 'Last 90 Days',
            value: [dayjs().add(-90, 'd'), dayjs()],
        },
    ];

    const [sortOrder, setSortOrder] = useState({
        column: null,
        ascending: true
    });

    const sortColumn = (columnName) => {
        const isAscending = sortOrder.column === columnName ? !sortOrder.ascending : true;
        const sortedData = [...listAnalytics].sort((a, b) => {
            if (a[columnName] < b[columnName]) return isAscending ? -1 : 1;
            if (a[columnName] > b[columnName]) return isAscending ? 1 : -1;
            return 0;
        });
        setListAnalytics(sortedData);
        setSortOrder({ column: columnName, ascending: isAscending });
    };

    const renderSortIcon = (columnName) => {
        if (sortOrder.column === columnName) {
            return sortOrder.ascending ? <CaretUpOutlined /> : <CaretDownOutlined />;
        }
        return null;
    };
    return (
        <>
            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                {/* row 1 */}
                <Grid item xs={12} sx={{ mb: -2.25 }}>
                    <Row>
                        <Col md={19}>
                            <Typography variant="h5">Product perfomance</Typography>

                        </Col>
                        <Col md={5}>
                            <RangePicker size="large" presets={rangePresets}
                                onChange={handleRangePickerChange} />
                        </Col>
                    </Row>
                </Grid>
                {
                    loading ? (
                        <div className="loader-container">
                            <HashLoader color="#5f99f8" loading={loading} />
                        </div>
                    ) : (
                        <>
                            <Grid md={24} item>

                                <Table className="table-custom" responsive>
                                    <thead style={{ background: "#f2f2f2", fontWeight: "bold" }}>
                                        <tr>
                                            <th ></th>
                                            <th>Name Product</th>
                                            <th>Seller Name</th>

                                            <th className='hoverColum' onClick={() => sortColumn('total_count_load_product')}>
                                                View Product {renderSortIcon('total_count_load_product')}
                                            </th>
                                            <th className='hoverColum' onClick={() => sortColumn('total_add_to_cart')}>
                                                Add to Cart {renderSortIcon('total_add_to_cart')}
                                            </th>
                                            <th className='hoverColum' onClick={() => sortColumn('total_checkout_process')}>
                                                Reach checkout {renderSortIcon('total_checkout_process')}
                                            </th>
                                            <th className='hoverColum' onClick={() => sortColumn('total_sales')}>
                                                Sales {renderSortIcon('total_sales')}
                                            </th>
                                            <th className='hoverColum' onClick={() => sortColumn('total_original_price')}>
                                                Origin Price {renderSortIcon('total_original_price')}
                                            </th>
                                            <th className='hoverColum' onClick={() => sortColumn('total_paid_orders')}>
                                                Total Order {renderSortIcon('total_paid_orders')}
                                            </th>
                                            <th className='hoverColum' onClick={() => sortColumn('conversion_rate')}>
                                                CR {renderSortIcon('conversion_rate')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listAnalytics.length === 0 ? (
                                            <tr>
                                                <td colSpan="10" style={{ textAlign: "center" }}>
                                                    <img src="https://store.vtctelecom.com.vn/Content/images/no-data.png" alt="No data" width={300} />
                                                </td>
                                            </tr>
                                        ) : (
                                            listAnalytics.map((product, index) => (
                                                <tr key={index}>
                                                    <td><img src={product.product_image_url} alt="Product" width={50} /></td>
                                                    <td style={{ width: "320px" }}>{product.name_product}</td>
                                                    <td>{product.name_seller}</td>

                                                    <td>{product.total_count_load_product}</td>
                                                    <td>{product.total_add_to_cart}</td>
                                                    <td>{product.total_checkout_process}</td>
                                                    <td>${product.total_sales.toFixed(2)}</td>
                                                    <td>${product.total_original_price.toFixed(2)}</td>
                                                    <td>{product.total_paid_orders}</td>
                                                    <td>{!isNaN(product.total_paid_orders / product.total_count_load_product) ?
                                                        ((product.total_paid_orders / product.total_count_load_product) * 100).toFixed(2) :
                                                        "0.00 "} %</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </Table>
                            </Grid>
                        </>
                    )}
            </Grid>


        </>
    );
}
export default ProductPerfomance;
