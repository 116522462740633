import { Button, Col, Image, Row, Modal, Form, DatePicker, Input, Card, Typography as TypographyAntd, message as antdMessage, Select } from "../../../node_modules/antd/es/index";
import Table from "../../../node_modules/react-bootstrap/esm/Table";
import { Typography } from "../../../node_modules/@mui/material/index";
import { useEffect, useState } from "react";
import HashLoader from "../../../node_modules/react-spinners/HashLoader";
import DateFormatConverter from "components/fomartDateTime/DateFormatConverter";
import withdrawalSerivce from "service/withdrawal-serivce";
import sellerService from "service/seller-service";

import { DollarCircleOutlined, FileTextOutlined, LineChartOutlined, WarningOutlined } from '@ant-design/icons';
const { Title, Text } = TypographyAntd;


const PayoutRequest = () => {
    const [historyPayout, setHistoryPayout] = useState([]);
    const [loadingHistory, setLoadingHistory] = useState(false)
    const fomartDate = new DateFormatConverter();


    useEffect(() => {

        findAllHistoryPayoutBySeller();
    }, []);

    const findAllHistoryPayoutBySeller = () => {
      const json = {
        sellerId: null
      }
        withdrawalSerivce.findAllHistory(json)
            .then(res => {
                setHistoryPayout(res.data.data)
                setLoadingHistory(false)
            }).catch(err => {
                console.log(err);
            })
    }
    return(
        <Card className='mt-3'>
        <Row>
            <Col md={24} className='mb-3'>
                <Typography variant="h5">Withdrawal History (All time)</Typography>
            </Col>

            <Col md={24}>
                <Table className="table-custom" responsive>
                    <thead style={{ background: "#f2f2f2", fontWeight: "bold" }}>
                        <tr>
                            <th>No</th>
                            <th>Withdraw Date</th>
                            <th>Transition Id</th>
                            <th>Sum cost</th>
                            <th>Profit</th>
                            <th>Payout</th>
                            <th>Status</th>

                        </tr>
                    </thead>
                    <tbody>
                        {loadingHistory ? (
                            <tr>
                                <td colSpan="10" style={{ textAlign: "center", verticalAlign: "middle", height: "50vh" }}>
                                    <div style={{ display: "inline-block" }}>
                                        <HashLoader color="#5f99f8" loading={loadingHistory} />
                                    </div>
                                </td>
                            </tr>
                        ) : !historyPayout || historyPayout.length === 0 ? (
                            <tr>
                                <td colSpan="10" style={{ textAlign: "center" }}>
                                    <Text style={{ color: "red" }}>
                                        <WarningOutlined style={{ marginRight: '8px' }} />
                                        Please select date and seller to display information
                                    </Text>                                            </td>
                            </tr>
                        ) : (
                            historyPayout.map((history, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{fomartDate.formatDateString(history.withdrawalDate) + " " + fomartDate.formatTimeString(history.withdrawalDate)}</td>
                                    <td>{history.transactionId}</td>
                                    <td>{history.allCost}</td>
                                    <td>{history.profit}</td>
                                    <td>{history.profit}</td>
                                    <td
                                        style={{
                                            color: history.status === true ? 'green' : 'red',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {history.status === true ? 'Paid' : 'Unpaid'}
                                    </td>
                                </tr>
                            ))

                        )}
                    </tbody>
                </Table>

            </Col>
        </Row>
    </Card>
    )

}


export default PayoutRequest;
