import { Col, Image, Row } from "../../../node_modules/antd/es/index";
import { Typography } from "../../../node_modules/@mui/material/index";
import Table from "../../../node_modules/react-bootstrap/esm/Table";
import { useEffect, useState } from "react";
import productService from "service/product.service";
import { useNavigate } from "../../../node_modules/react-router-dom/dist/index";
import { Input, Select, DatePicker, Button, message as antdMessage } from 'antd';
import { EditOutlined, ExportOutlined } from '@ant-design/icons';
import HashLoader from "../../../node_modules/react-spinners/HashLoader";

const MyProduct = () => {
    const navigate = useNavigate();

    const [listProduct, setListProduct] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        findAllProduct();
    }, []);

    const findAllProduct = () => {
        setLoading(true)
        productService.findAllProduct()
            .then(res => {
                setListProduct(res.data.data)
                setLoading(false)
            }).catch(err => {
                console.log(err);
                if (err.response.status === 401) {
                    navigate("/login")
                }
            })
    }

    return (
        <>
            <Row>

                <Col md={24} className='mb-3' >
                    <Typography variant="h5">Product</Typography>
                </Col>
                <Col md={24} className="table-custom-responsive">
                    <Table className="table-custom" responsive>
                        <thead style={{ background: "#f2f2f2", fontWeight: "bold" }}>
                            <tr>
                                <th>#</th>
                                <th style={{ width: "10px" }}>Image</th>
                                <th>Name Product</th>
                                <th>Create Date</th>
                                <th>Seller namer</th>
                                <th>Price</th>
                                <th>Acction</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? (
                                    <tr>
                                        <td colSpan="10" style={{ textAlign: "center", verticalAlign: "middle", height: "50vh" }}>
                                            <div style={{ display: "inline-block" }}>
                                                <HashLoader color="#5f99f8" loading={loading} />
                                            </div>
                                        </td>
                                    </tr>
                                ) : (

                                    listProduct.length === 0 ? (
                                        <tr>
                                            <td colSpan="10" style={{ textAlign: "center" }}><Image preview={false} width={300} src="https://store.vtctelecom.com.vn/Content/images/no-data.png"></Image></td>
                                        </tr>
                                    ) : (
                                        listProduct.map((product, index) => (
                                            <tr key={index} >
                                                <td> {index + 1}</td>
                                                <td><Image preview={false} width={50} src={product.productEntity.imageEntity[0].urlImage} /></td>
                                                <td style={{ width: "500px" }}> {product.productEntity.nameProduct}</td>
                                                <td> {product.productEntity.createDate}</td>
                                                <td> {product.sellerEntity.nameSeller}</td>
                                                <td>
                                                    ${product.productEntity.templateComboEntity[0].price.toFixed(2)}
                                                </td>
                                                <td>
                                                    <Button><EditOutlined /></Button>
                                                    <Button target="_blank" href={process.env.REACT_APP_URL_PRODUCT + "/" + product.urlLinkProduct}><ExportOutlined /></Button>
                                                </td>
                                            </tr>
                                        ))
                                    ))}
                        </tbody>
                    </Table>
                </Col>
            </Row >
        </>
    );
}
export default MyProduct;
