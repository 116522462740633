
import { Button, Col, Image, Row, Modal, Form, DatePicker, Input, Card, Typography as TypographyAntd, message as antdMessage, Select } from "../../../node_modules/antd/es/index";
import Table from "../../../node_modules/react-bootstrap/esm/Table";
import { Typography } from "../../../node_modules/@mui/material/index";
import { useEffect, useState } from "react";
import HashLoader from "../../../node_modules/react-spinners/HashLoader";
import DateFormatConverter from "components/fomartDateTime/DateFormatConverter";
import withdrawalSerivce from "service/withdrawal-serivce";

import { DollarCircleOutlined, FileTextOutlined, LineChartOutlined, WarningOutlined } from '@ant-design/icons';
const { Title, Text } = TypographyAntd;
const { RangePicker } = DatePicker;
const { Option } = Select;

const PayoutSupManager = () => {

    const [loading, setLoading] = useState(false)
    const [listPayout, setListPayout] = useState([]);

    const [searchPayout, setSearchPayout] = useState({
        startDate: null,
        endDate: null,
    })

    useEffect(() => {
        findAllListSaleBySup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchPayout]);
    const findAllListSaleBySup = () => {
        setLoading(true)
        withdrawalSerivce.getSaleBySup(searchPayout)
            .then(res => {
                setListPayout(res.data.data)
                setLoading(false)
            }).catch(err => {
                console.log(err);
            })
    }

    const handleRangePickerChange = (dates, dateStrings) => {
        if (dates) {
            setSearchPayout({
                ...searchPayout,
                startDate: dates[0].format('YYYY-MM-DDTHH:mm:ss'),
                endDate: dates[1].format('YYYY-MM-DDTHH:mm:ss')
            });
        } else {
            setSearchPayout({
                ...searchPayout,
                startDate: null,
                endDate: null
            });
        }

    };
    return (
        <>
            <Col md={24} className='mb-3'>
                <Typography variant="h5">Payout sup manager</Typography>
            </Col>
            <Col md={7} className="mb-3">
                <RangePicker size="large" style={{ width: '100%' }}
                    onChange={handleRangePickerChange} showTime />
            </Col>
            <Card>

                <Row gutter={[16, 16]} justify="center">


                    <Col span={8}>
                        <Card className="summary-card" bordered={false}>
                            <DollarCircleOutlined className="summary-icon" style={{ color: 'blue' }} />
                            <Title level={4}>Sum payout (Sum Cost)</Title>
                            <Title level={3}>{listPayout?.sumPayout ? parseFloat(listPayout.sumPayout).toFixed(2) : '0.00'}</Title>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card className="summary-card" bordered={false}>
                            <FileTextOutlined className="summary-icon" style={{ color: 'yellow' }} />
                            <Title level={4}>Total Order Detail</Title>
                            <Title level={3}>{listPayout?.quantityProduct ? listPayout.quantityProduct : '0.00'}</Title>
                        </Card>
                    </Col>

                </Row>
                {listPayout?.sumPayout && (
                    <Col style={{ textAlign: "end" }} className="mt-3">
                        <Button
                            type="primary"
                            icon={<DollarCircleOutlined />}
                            // onClick={handlePayoutClick}
                            size="large"
                            style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            Payout
                        </Button>
                    </Col>
                )}
                <Row gutter={[16, 2]}>

                    <Col md={24} className="table-custom-responsive">
                        <Table className="table-custom" responsive>
                            <thead style={{ background: "#f2f2f2", fontWeight: "bold" }}>
                                <tr>
                                    <th>Order Id</th>
                                    <th>Product Info</th>
                                    <th>Name Variant</th>
                                    <th>Fulfilment Price</th>
                                    <th>Name Customer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="10" style={{ textAlign: "center", verticalAlign: "middle", height: "50vh" }}>
                                            <div style={{ display: "inline-block" }}>
                                                <HashLoader color="#5f99f8" loading={loading} />
                                            </div>
                                        </td>
                                    </tr>
                                ) : (
                                    (!listPayout || !listPayout.infoPayoutResponseList || listPayout.infoPayoutResponseList.length === 0) ? (
                                        <tr>
                                            <td colSpan="10" style={{ textAlign: "center" }}>
                                                <Text style={{ color: "red" }}>
                                                    <WarningOutlined style={{ marginRight: '8px' }} />
                                                    Please select date to display information
                                                </Text>                                         
                                                  </td>
                                        </tr>
                                    ) : (
                                        listPayout.infoPayoutResponseList.map((saleSup, index) => (
                                            <tr key={index}>
                                            <td>{saleSup.idOrderDetail}</td>

                                                <td> <a target="_blank"
                                                    href={process.env.REACT_APP_URL_PRODUCT + "/" + saleSup.productInfo}
                                                    rel="noreferrer"> {process.env.REACT_APP_URL_PRODUCT + "/" + saleSup.productInfo} </a></td>
                                                <td>{saleSup.nameTemplateCB}</td>

                                                <td>{parseFloat(saleSup.fulfilmentPrice).toFixed(2)}</td>
                                                <td>{saleSup.nameCustomer}</td>

                                            </tr>
                                        ))
                                    )
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>




            </Card></>
    );
}

export default PayoutSupManager;
