import { Tabs, Card, Button, Input, Modal, message as antdMessage } from 'antd';
import { AutoComplete, Col, Image, Row, Select } from '../../../node_modules/antd/es/index';
import { Typography } from "../../../node_modules/@mui/material/index";
import { useEffect, useState } from 'react';
import settingService from 'service/setting.service';
import Icon, { DeleteOutlined, SearchOutlined, MinusCircleOutlined } from '@ant-design/icons';
import Table from '../../../node_modules/react-bootstrap/esm/Table';
import productService from 'service/product.service';
import { confirmAlert } from "react-confirm-alert"; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import "./pixel.css"

import sellerService from "service/seller-service";


const { Option } = Select;

const PixelFacebookManager = () => {

    const [facebookPixel, setFacebookPixel] = useState([])
    const [productPixel, setProductPixel] = useState([])

    const [pixelId, setPixelId] = useState('');
    const [searchProduct, setSearchProduct] = useState("");
    const [options, setOptions] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openModalCreate, setOpenModalCreate] = useState(false);
    //shipping

    const [listSeller, setListSeller] = useState([]);

    const [searchSeller, setSearchSeller] = useState({
        sellerId: null,
        nameProduct: null
    })

    const handleSellerTemplateSelect = (selectedId) => {

        setSearchSeller({
            sellerId: selectedId
        });

    };

    useEffect(() => {
        findAllSeller();
        findAllpixel();
    }, [searchSeller]);


    console.log(searchSeller);


    const findAllpixel = () => {
      
        settingService.findAllBySeller(searchSeller)
            .then(res => {
                setFacebookPixel(res.data.data)
            }).catch(err => {
                console.log(err);
            })
    }
    const showPixelProduct = (pixel) => {
        setProductPixel(pixel)
        setOpenModal(true)
    }

    const handleSearchView = (value) => {
        setSearchProduct(value);
        if (value) {
            findProductByName(value);
        } else {
            setOptions([]);
        }
    }


    const handleSelectView = (value, option) => {
        const product = JSON.parse(value);
        setProductPixel(prevState => {
            const productExists = prevState.products.some(selectedProduct => selectedProduct.idProduct === product.idProduct);
            if (!productExists) {
                const newProducts = [...prevState.products, product];
                return { ...prevState, products: newProducts };
            } else {
                antdMessage.warning("Product already exists");
                return prevState;
            }
        });
        setSearchProduct('');
    };

    const addProductToPixel = () => {
        if (productPixel.products.length === 0) {
            antdMessage.error("Product id not null")
            return;
        }
        const transformedData = {
            idPixel: productPixel.pixelId,
            idProducts: productPixel.products
                .filter(product => product.idProduct !== null) // Lọc bỏ sản phẩm có idProduct là null
                .map(product => product.idProduct) // Chỉ lấy idProduct
        };
        settingService.createFacebookPixel(transformedData)
            .then(res => {
                antdMessage.success("Create Facebook Pixel success")
                findAllpixel();
                setOpenModal(false)

            }).catch(err => {
                console.log(err);
                antdMessage.error("Create Facebook Pixel success")

            })
    }


    const showModalAddPixel = () => {
        setOpenModalCreate(true)
    }
    const findProductByName = (value) => {
        const jsonSearch = {
            nameProduct: value,
            idSeller: searchSeller.sellerId
        }
        productService.findProductByName(jsonSearch)
            .then(res => {
                if (res.data.ecode === "200") {
                    setOptions(
                        res.data.data.map((product) => ({
                            value: JSON.stringify({ idProduct: product.idProduct, nameProduct: product.nameProduct, active: product.active, image: product.image }),
                            label: (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {product.nameProduct}
                                    <img src={product.image} alt={product.nameProduct} style={{ width: 50, height: 50 }} />

                                </div>
                            ),
                        }))
                    );
                }
            }).catch(err => {
                console.log(err);
            })
    }

    const handleSearch = (value) => {
        setSearchProduct(value);
        if (value) {
            findProductByName(value);
        } else {
            setOptions([]);
        }
    };

    const handleSelect = (value, option) => {
        const product = JSON.parse(value);

        // Kiểm tra xem selectedProducts có giá trị không
        if (selectedProducts) {
            const productExists = selectedProducts.some(selectedProduct => selectedProduct.idProduct === product.idProduct);

            if (!productExists) {
                setSelectedProducts(prevSelectedProducts => [...prevSelectedProducts, product]);
            } else {
                antdMessage.warning("Product already exists");
            }
        } else {
            setSelectedProducts(prevSelectedProducts => [...prevSelectedProducts, product]);
            console.error('selectedProducts is null or undefined.');
        }

        setSearchProduct('');
    };

    const addProductPixel = () => {
        if (pixelId === "") {
            antdMessage.error("Pixel id not null")
            return;
        } if (selectedProducts.length === 0) {
            antdMessage.error("Product id not null")
            return;
        }
        const jsonPayload = {
            idPixel: pixelId,
            idProducts: selectedProducts.map(product => product.idProduct)
        }
        settingService.createFacebookPixel(jsonPayload)
            .then(res => {
                antdMessage.success("Create Facebook Pixel success")
                findAllpixel();
                setPixelId("")
                setSelectedProducts(null)
                setOpenModalCreate(false)
                setSelectedProducts("")

            }).catch(err => {
                console.log(err);
                antdMessage.error("Create Facebook Pixel success")

            })
    }

    const handlePixelIdChange = (e) => {
        setPixelId(e.target.value);
    };


    const handleDeleteProduct = (productId) => {
        setSelectedProducts(selectedProducts.filter(product => product.id !== productId));
    };

    const confirmDelete = (idPixel) => {
        if (idPixel) {
            confirmAlert({
                message:
                    `Are you sure you want to delete pixel :` + idPixel + `?`,
                buttons: [
                    {
                        label: "Return",
                        className: "stayPage",
                    },
                    {
                        label: "Confirm",
                        onClick: () => deletePixel(idPixel),
                        className: "leavePage",
                    },
                ],
            });
        }
    };

    const deletePixel = (pixelId) => {
        const json = {
            idPixel: pixelId
        }
        settingService.deleteFacebookPixcel(json)
            .then(res => {
                antdMessage.success("Delete pixel success")
                findAllpixel()
            }).catch(err => {
                console.log(err);
                antdMessage.error("Delete fail");
            })
    }

    const findAllSeller = () => {
        sellerService.findAllSeller()
            .then(res => {
                setListSeller(res.data.data)
            }).catch(err => {
                console.log(err);

            })
    }

    const deleteOneProductPixcel = (pixelId, productId) => {
        const json = {
            idPixel: pixelId,
            idProduct: productId
        }
        settingService.deleteFacebookPixcelByIdPro(json)
            .then(res => {
                antdMessage.success("Delete pixel success")
            }).catch(err => {
                console.log(err);
                antdMessage.error("Delete fail");
            })
    }

    return (
        <>
            <Col md={5} className="mb-3">
                <label style={{ display: "block", marginBottom: "8px", fontSize: "16px", fontFamily: "Roboto", fontWeight: "bold" }}>
                    Please select seller
                </label>
                <Select
                    size="large"
                    onChange={handleSellerTemplateSelect}
                    name="idSeller"
                    placeholder="Select seller"
                    style={{ width: "100%" }}
                    required
                >
                    {listSeller.map(seller => (
                        <Option key={seller.idSeller} value={seller.idSeller}>
                            {seller.idSeller + ". " + seller.nameSeller}
                        </Option>
                    ))}
                </Select>
            </Col>

            <Card>
                <Row>
                    <Col md={6}>
                        <span style={{ fontSize: "18px", fontWeight: "bold" }}>Facebook fixel</span>
                    </Col>
                    <Col md={18}>
                        {facebookPixel.map((fbpixel, index) => (
                            <Row key={index}>
                                <Col md={24}>
                                    <span style={{ fontSize: "13px", color: "#8e9bb7" }}>
                                        Pixel id
                                    </span>
                                </Col>
                                <Col md={6}>
                                    <span style={{ fontSize: "17px" }}>
                                        {fbpixel.pixelId}
                                    </span>
                                </Col>
                                <Col md={6} onClick={() => showPixelProduct(fbpixel)}>
                                    <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                                        View  {fbpixel.products.length} Track product
                                    </span>
                                </Col>
                                <Col md={6} style={{ textAlign: "end", color: "red" }} className="mb-3"  >
                                    <DeleteOutlined onClick={() => confirmDelete(fbpixel.pixelId)} />
                                </Col>
                            </Row>
                        ))}
                        <Col md={24}>
                            <Button type="primary" size="large" onClick={showModalAddPixel}>Add pixel ID</Button>
                        </Col>
                    </Col>
                </Row>
            </Card>

            <Modal
                title="View tracked Product"
                centered
                open={openModal}
                onOk={() => addProductToPixel()}
                onCancel={() => setOpenModal(false)}
                width={800}
            >
                <Row>
                    <Col md={2}>
                        <span style={{ fontSize: "15px", color: "#8e9bb7" }}>
                            Pixel id:
                        </span>
                    </Col>
                    <Col md={18}>
                        <span style={{ fontSize: "15px" }}>
                            {productPixel.pixelId}
                        </span>
                    </Col>
                    <Col md={24} >
                        <div style={{ maxHeight: "400px", overflowY: "auto" }}>

                            <Table className="table-custom" responsive  >
                                <thead>
                                    <tr>
                                        <th colSpan="4">
                                            <AutoComplete
                                                options={options}
                                                style={{ width: "100%" }}
                                                onSearch={handleSearchView}
                                                placeholder="Search product"
                                                onSelect={handleSelectView}
                                                value={searchProduct}
                                            />                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productPixel.products?.map((product, index) => (
                                        <tr key={index}>
                                            <td ><DeleteOutlined style={{ color: "red", fontSize: "15px", marginTop: "18px" }} onClick={() => deleteOneProductPixcel(product.pixelIs, product.idProduct)} /></td>
                                            <td>
                                                <Image src={product.imageUrl || product.image} width="50px" alt={product.nameProduct} />
                                            </td>
                                            <td>
                                                <span style={{ fontSize: "15px" }}>
                                                    {product.nameProduct}
                                                </span>
                                            </td>
                                            <td>
                                                <span style={{
                                                    borderColor: product.active ? '#dbfae6' : 'gray',
                                                    borderWidth: '1px',
                                                    color: product.active ? 'black' : 'white',
                                                    backgroundColor: product.active ? '#dbfae6' : 'gray',
                                                    borderStyle: 'solid',
                                                    padding: '5px',
                                                    borderRadius: '10px'
                                                    , marginTop: "18px"
                                                }}>
                                                    {product.active ? 'Active' : 'Inactive'}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>

                    </Col>
                </Row>
            </Modal>

            <Modal
                title="Add Facebook pixel"
                centered
                open={openModalCreate}
                onOk={() => addProductPixel()}
                onCancel={() => setOpenModalCreate(false)}
                width={800}            >
                <Row>
                    <Col md={24}>
                        <span style={{ fontSize: "15px", color: "#8e9bb7" }}>
                            Pixel id:
                        </span>
                    </Col>
                    <Col md={18}>
                        <Input
                            size="large"
                            value={pixelId}
                            onChange={handlePixelIdChange}
                            placeholder="Paste pixel ID" />
                    </Col>
                    <Col md={24}>
                        <div style={{ maxHeight: "400px", overflowY: "auto" }}>

                            <Table className="table-custom" responsive >
                                <thead>
                                    <tr>
                                        <th colSpan="4">
                                            <AutoComplete
                                                options={options}
                                                style={{ width: "100%" }}
                                                onSearch={handleSearch}
                                                placeholder="Search product"
                                                onSelect={handleSelect}
                                                value={searchProduct}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedProducts?.length > 0 ? (
                                        selectedProducts.map((product, index) => (
                                            <tr key={index}>
                                                <td><DeleteOutlined style={{ color: "red", fontSize: "15px", marginTop: "18px" }} onClick={() => handleDeleteProduct(product.id)} /></td>
                                                <td>
                                                    <Image src={product.image} width="50px" alt={product.nameProduct} />
                                                </td>
                                                <td>
                                                    <span style={{ fontSize: "15px" }}>
                                                        {product.nameProduct}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span style={{
                                                        borderColor: product.active ? '#dbfae6' : 'gray',
                                                        borderWidth: '1px',
                                                        color: product.active ? 'black' : 'white',
                                                        backgroundColor: product.active ? '#dbfae6' : 'gray',
                                                        borderStyle: 'solid',
                                                        padding: '5px',
                                                        borderRadius: '10px', marginTop: "18px"
                                                    }}>
                                                        {product.active ? 'Active' : 'Inactive'}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4" style={{ textAlign: "center", padding: "20px" }}>
                                                Search & Add product
                                                to Apply Pixel.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    );
}

export default PixelFacebookManager;
