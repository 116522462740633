import { Button, Col, Row, message, Card, Form, Input, Switch, Typography as typeAd, Image, Modal, InputNumber, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import SunEditor from 'suneditor-react';
import Table from "../../../node_modules/react-bootstrap/esm/Table";

import 'suneditor/dist/css/suneditor.min.css';
import {
    FileImageOutlined,
    DeleteOutlined,
    StarFilled
} from '@ant-design/icons';
import { Typography } from "../../../node_modules/@mui/material/index";
import moment from 'moment';
import sellerService from 'service/seller-service';
import productService from 'service/product.service';
const { TextArea } = Input;
const { Text } = typeAd;
const { Option } = Select;

const CreateProduct = () => {


    const [inputList, setInputList] = useState(['']);
    const [formData, setFormData] = useState({
        nameProduct: "",
        idProduct: '',
        logoBanner: "",
        description: "",
        productReviewList: [],
        templateComboList: [],
        templateSizeList: [],
        imageList: [],
        templateProduct: {
            active: true,
            description: "This is the main product description.",
            idSeller: "",
            name: "",
            urlLinkProduct: ""
        }
    });

    const [listSeller, setListSeller] = useState([]);

    const [isModalComboVisible, setIsModalComboVisible] = useState(false);
    const [isModalSizeVisible, setIsModalSizeVisible] = useState(false);
    const [formCombo] = Form.useForm(); // Tạo biến tham chiếu đến form
    const [formSize] = Form.useForm(); // Tạo biến tham chiếu đến form


    const [productListReview, setProductListReview] = useState({
        name: '',
        reviewContent: '',
        email: '',
        date: new Date(),
        star: 0,
        urlImage: '',
        isReviewAdded: false
    })

    const editorRef = useRef(null);


    useEffect(() => {
        findAllSeller();
    }, []);



    const handleContentChange = (content) => {
        setFormData(prevData => ({
            ...prevData,
            description: content
        }));
    };
    const handleInputChangeProduct = (e) => {
        const { name, value } = e.target;
        setProductListReview({
            ...productListReview,
            [name]: value
        });
    };

    const handleStarClick = (index) => {
        setProductListReview({
            ...productListReview,
            star: index + 1
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleInputChangeTemplateProduct = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            templateProduct: {
                ...formData.templateProduct,
                [name]: value
            }
        });
    };



    const handleAddReview = () => {
        const updatedFormData = { ...formData };
        const existingReviews = [...updatedFormData.productReviewList];
        existingReviews.push(productListReview);
        updatedFormData.productReviewList = existingReviews;
        setFormData(updatedFormData);
        setProductListReview({
            name: '',
            reviewContent: '',
            email: '',
            star: 0,
            urlImage: '',
        });
    };

    const showModalAddCombo = () => {
        setIsModalComboVisible(true); // Hàm để mở modal
    };


    const handleOkModalCb = (values) => {
        const updatedFormData = { ...formData };
        const existingReviews = [...updatedFormData.templateComboList];
        existingReviews.push(values);
        updatedFormData.templateComboList = existingReviews;
        setFormData(updatedFormData);
        setIsModalComboVisible(false);
        // formCombo.resetFields(); // Làm sạch form

    };

    const handleDeleteCombo = (index) => {
        const newData = { ...formData };
        newData.templateComboList.splice(index, 1);
        setFormData(newData);
    };

    const showModalAddSize = () => {
        setIsModalSizeVisible(true); // Hàm để mở modal
    };


    const handleOkModalSize = (values) => {
        console.log('Form values:', values); // Lấy giá trị của form khi submit
        const updatedFormData = { ...formData };
        const existingReviews = [...updatedFormData.templateSizeList];
        existingReviews.push(values);
        updatedFormData.templateSizeList = existingReviews;
        setFormData(updatedFormData);
        setIsModalSizeVisible(false);
        formSize.resetFields(); // Làm sạch form

    };

    const handleDeleteSize = (index) => {
        const newData = { ...formData };
        newData.templateSizeList.splice(index, 1);
        setFormData(newData);
    };


    //image list
    const handleInputChangeImage = (index, value) => {
        const list = [...inputList];
        list[index] = value;
        setInputList(list);

        setFormData({
            ...formData,
            imageList: list.filter(url => url.trim() !== '')
        });
    };

    const handleAddInput = () => {
        setInputList([...inputList, '']);

    };

    const handleRemoveInput = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);

        setFormData({
            ...formData,
            imageList: list.filter(url => url.trim() !== '')
        });
    };

    const renderStars = (starCount) => {
        let stars = [];
        for (let i = 0; i < starCount; i++) {
            stars.push(<span key={i}>⭐</span>);
        }
        return stars;
    };

    const formatDate = (date) => {
        return moment(date).format("YYYY-MM-DD hh:mm:ss A");
    }


    const findAllSeller = () => {
        sellerService.findAllSeller()
            .then(res => {
                setListSeller(res.data.data)
            }).catch(err => {
                console.log(err);

            })
    }


    const handleSellerTemplateSelect = (selectedId) => {
        setFormData({
            ...formData,
            templateProduct: {
                ...formData.templateProduct,
                idSeller: selectedId // Cập nhật idSeller khi người dùng chọn
            }
        });
    };

    const saveProduct = () => {
        productService.createProduct(formData)
            .then(res => {
                message.success("Create product Done")
            }).catch(err => {
                message.error("Create product fail")
            })
    }
    console.log('====================================');
    console.log(formData);
    console.log('====================================');


    return (
        <>
            <Row gutter={[16, 16]} >
                <Col md={20} >
                    <Card>
                        <Form.Item
                            label={<span style={{ fontSize: '18px' }}>Page link</span>}
                            name="username"
                        >
                            <Input addonBefore={process.env.REACT_APP_URL_PRODUCT + "/"}
                                name="urlLinkProduct"
                                value={formData.templateProduct.urlLinkProduct}
                                onChange={handleInputChangeTemplateProduct}
                                size="large"
                            />

                        </Form.Item>
                    </Card>

                </Col>
                <Col md={4}>
                    <Card> One page mode  <Switch defaultChecked /></Card>
                </Col>
                <Col md={24}>
                    <Typography variant="h5">Product Image</Typography>

                </Col>
                <Col md={20}>
                    <Card>
                        <Button onClick={handleAddInput} size="large"><FileImageOutlined /></Button>
                        <Row gutter={[16, 16]} className={"mt-3"}>
                            {inputList.map((value, index) => (
                                <Col md="24" key={index}>
                                    <div style={{ position: 'relative' }}>
                                        <Input
                                            value={value}
                                            onChange={(e) => handleInputChangeImage(index, e.target.value)}
                                            style={{ marginBottom: '10px' }}
                                            size="large"
                                        />
                                        {inputList.length > 1 && (
                                            <Button
                                                type="link"
                                                icon={<DeleteOutlined />}
                                                style={{
                                                    position: 'absolute',
                                                    right: '8px',
                                                    top: '40%',
                                                    transform: 'translateY(-50%)',
                                                    color: "red"
                                                }}
                                                onClick={() => handleRemoveInput(index)}
                                            />
                                        )}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Col md={24} >
                            <Text>Logo header</Text>
                            <TextArea name="logoBanner" value={formData.logoBanner} onChange={handleInputChange} className='mt-3' placeholder="Image logo" />
                        </Col>
                    </Card>
                </Col>
                <Col md={24}>
                    <Typography variant="h5">Information</Typography>
                </Col>
                <Col md={20}>
                    <Card>
                        <Row gutter={[16, 16]}>
                            <Col md={16} >
                                <Text>Name</Text>
                                <TextArea name="nameProduct" value={formData.nameProduct} onChange={handleInputChange} className='mt-3' placeholder="Name product" />
                            </Col>
                            <Col md={6}>
                                <Text>SKU</Text>
                                <TextArea name="idProduct" value={formData.idProduct} onChange={handleInputChange} className='mt-3' placeholder="SKU product" />
                            </Col>
                            <Col md={24}>
                                <SunEditor
                                    ref={editorRef}
                                    className="mt-4"
                                    onChange={handleContentChange}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Col md={24} >
                            <Text>By Seller</Text>
                            <Select
                                className="mt-3"
                                value={formData.templateProduct.idSeller}
                                onChange={handleSellerTemplateSelect} // Xử lý khi người dùng chọn
                                name="idSeller" // Đặt tên cho Select để sử dụng trong form submission
                                placeholder="Seller"
                                style={{ width: "100%" }}
                            >
                                {listSeller.map(seller => (
                                    <Option key={seller.idSeller} value={seller.idSeller}>
                                        {seller.nameSeller}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Card>
                </Col>
                <Col md={24}>
                    <Typography variant="h5">Variant</Typography>
                </Col>
                <Col md={20}>
                    <Card>
                        <Row >
                            <Col md={24} style={{ textAlign: "end" }}>
                                <Button onClick={showModalAddCombo}> Add combo</Button>
                                <Modal
                                    title="Add Combo" // Tiêu đề của modal
                                    open={isModalComboVisible} // Xác định xem modal có được hiển thị hay không
                                    onCancel={() => setIsModalComboVisible(false)}
                                    footer={null}
                                >
                                    <Form
                                        name="comboForm"
                                        layout="vertical" // Chỉ định kiểu layout cho form
                                        onFinish={handleOkModalCb}
                                        form={formCombo} // Sử dụng biến tham chiếu form

                                    >
                                        <Form.Item
                                            label="Name Combo"
                                            name="nameCombo"
                                            rules={[{ required: true, message: 'Please input the name of the combo!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Compare Price"
                                            name="comparePrice"
                                            rules={[{ required: true, type: 'number', min: 0, message: 'Please input the compare price!' }]}
                                        >
                                            <InputNumber min={0} style={{ width: '100%' }} />
                                        </Form.Item>
                                        <Form.Item
                                            label="Fulfillment Price"
                                            name="fulfillmentPrice"
                                            rules={[{ required: true, type: 'number', min: 0, message: 'Please input the fulfillment price!' }]}
                                        >
                                            <InputNumber min={0} style={{ width: '100%' }} />
                                        </Form.Item>
                                        <Form.Item
                                            label="Price"
                                            name="price"
                                            rules={[{ required: true, type: 'number', min: 0, message: 'Please input the price!' }]}
                                        >
                                            <InputNumber min={0} style={{ width: '100%' }} />
                                        </Form.Item>
                                        <Form.Item
                                            label="Image Combo"
                                            name="imageCombo"
                                            rules={[{ required: true, message: 'Please input the image URL of the combo!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">Add</Button>
                                        </Form.Item>
                                    </Form>
                                </Modal>
                            </Col>
                            <Col md={24} className="table-custom-responsive" style={{ maxHeight: '400px', overflowY: 'auto', height: "400px" }}>
                                <Table className="table-custom" responsive>
                                    <thead style={{ background: "#f2f2f2", fontWeight: "bold" }}>
                                        <tr>
                                            <th>#</th>
                                            <th>Image</th>
                                            <th>Varial </th>
                                            <th>Min sell price</th>
                                            <th>Price</th>
                                            <th>Compare price</th>
                                            <th>Acction</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.templateComboList?.length > 0 && formData.templateComboList.map((combo, index) => (
                                            <>
                                                <tr key={index} >
                                                    <td> {index + 1}</td>
                                                    <td><Image width={50} src={combo.imageCombo} /></td>
                                                    <td style={{ width: "350px" }}> {combo.nameCombo}</td>
                                                    <td> {combo.fulfillmentPrice.toFixed(2)}$</td>
                                                    <td> {combo.price.toFixed(2)}$</td>
                                                    <td> {combo.comparePrice.toFixed(2)}$</td>
                                                    <td>
                                                        <DeleteOutlined onClick={() => handleDeleteCombo(index)} />
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Col style={{ textAlign: "end" }}>
                            <Button onClick={showModalAddSize}> Add Size</Button>
                        </Col>
                        <Modal
                            title="Add Size"
                            open={isModalSizeVisible}
                            form={formSize} // Sử dụng biến tham chiếu form
                            onCancel={() => setIsModalSizeVisible(false)}
                            footer={null}
                        >
                            <Form
                                name="comboForm"
                                layout="vertical"
                                onFinish={handleOkModalSize}
                            >
                                <Form.Item
                                    label="Name Size"
                                    name="nameSize"
                                    rules={[{ required: true, message: 'Please input the name of the size!' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Add</Button>
                                </Form.Item>
                            </Form>
                        </Modal>
                        <Col style={{ maxHeight: '400px', overflowY: 'auto', height: "400px" }}>
                            <Table className="table-custom" responsive>
                                <thead style={{ background: "#f2f2f2", fontWeight: "bold" }}>
                                    <tr>
                                        <th >Name</th>
                                        <th></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.templateSizeList?.length > 0 && formData.templateSizeList.map((size, index) => (
                                        <>
                                            <tr key={index} >
                                                {/* <td> {index + 1}</td> */}
                                                <td style={{ width: "70px" }}>  {size.nameSize}</td>
                                                <td>
                                                    <DeleteOutlined onClick={() => handleDeleteSize(size)} />
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Card>
                </Col>
                <Col md={24}>
                    <Typography variant="h5">Product review
                    </Typography>

                </Col>
                <Col md={20} >
                    <Card>
                        <Row gutter={[20, 20]}>
                            <Col span={8}>
                                <Col md={24}>
                                    <Text>Name</Text>
                                    <Input name="name" value={productListReview.name} onChange={handleInputChangeProduct} className='mt-3' placeholder="Review name" />
                                </Col>
                                <Col md={24} className="mt-3">
                                    <Text>Email</Text>
                                    <Input name="email" value={productListReview.email} onChange={handleInputChangeProduct} className='mt-3' placeholder="Review name" />
                                </Col>
                                <Col md={24} className="mt-3">
                                    <Row>
                                        <Col md={10}>
                                            <Text>Product Quality:</Text>
                                        </Col>
                                        <Col md={12}>
                                            <div className="star-rating-wrapper">
                                                {[0, 1, 2, 3, 4].map(index => (
                                                    <StarFilled
                                                        key={index}
                                                        className={index < productListReview.star ? "star-icon active" : "star-icon"}
                                                        style={{ color: index < productListReview.star ? '#FFD700' : 'rgba(0, 0, 0, 0.65)', fontSize: "24px" }}
                                                        onClick={() => handleStarClick(index)}
                                                    />
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={24} className="mt-3">
                                    <Text>Image</Text>
                                    <Input name="urlImage" value={productListReview.urlImage} className='mt-3' onChange={handleInputChangeProduct} placeholder="Link photo" />
                                </Col>
                                <Col md={24} className="mt-3">
                                    <Text>Comment</Text>
                                    <TextArea name="reviewContent" value={productListReview.reviewContent} className='mt-3' onChange={handleInputChangeProduct} rows={4} placeholder="Share your thought about this product.." />
                                </Col>
                                <Col md={24} className="mt-3">
                                    <Button style={{ width: "100%", color: "#4789f7" }} size="large" type="text" onClick={handleAddReview}>
                                        Add review +
                                    </Button>
                                </Col>
                            </Col>
                            <div className="vertical-divider" style={{ borderLeft: "1px solid #ccc" }}></div>
                            <Col span={15} style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                {formData.productReviewList?.length > 0 && formData.productReviewList.map((review, index) => (
                                    <>
                                        <Row className='mb-3' key={index}>
                                            <Col md={24}><Text style={{ fontWeight: "bold", fontSize: "14px" }} >{review.name}</Text></Col>
                                            <Col md={24}><Text style={{ fontWeight: "bold", fontSize: "14px" }} >{renderStars(review.star)} </Text></Col>
                                            <Col md={24}><Text style={{ fontSize: "14px", fontStyle: "italic" }} > {formatDate(review.date)}</Text></Col>
                                            <Col md={24}><Text style={{ fontSize: "14px" }} > {review.reviewContent}</Text></Col>
                                            <Col md={24}><Image src={review.urlImage} width={100} /></Col>
                                        </Row>
                                        <hr color="brown" noshade="noshade" />

                                    </>
                                ))
                                }

                            </Col>
                        </Row>
                    </Card>
                    <Col className="mt-3 mb-3">
                        <Button style={{ width: "100%" }} size="large" onClick={saveProduct}>Save Product</Button>
                    </Col>
                </Col>

            </Row>
        </>
    );
}
export default CreateProduct;
