import { Component } from "react";

class CurrencyConverter extends Component {
    formatCurrency(amount) {
        // Use Intl.NumberFormat to format the amount as USD
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(amount);
    }
}
export default CurrencyConverter;
