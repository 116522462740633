import { Button, Card, Col, Image, Row } from "../../../node_modules/antd/es/index";
import { Typography } from "../../../node_modules/@mui/material/index";
import sellerService from "service/seller-service";
import { useEffect, useState } from "react";
import Table from "../../../node_modules/react-bootstrap/esm/Table";
import HashLoader from "../../../node_modules/react-spinners/HashLoader";
const TopSeller = () => {
    const [rankSeller, setRankSeller] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        topRankSeller();
    }, []);

    const topRankSeller = () => {
        setLoading(true)
        sellerService.rankingSeller()
            .then(res => {
                console.log(res);
                setRankSeller(res.data.data)
                setLoading(false)
            }).catch(err => {
                console.log(err);
            })
    }

    return (
        <>
            {/* <Col md={24} className='mb-3'>
                <Typography variant="h5">Top seller</Typography>
            </Col> */}
            <Row>
                <Col md={24}>
                    <Card>
                        <Image src="https://i.imgur.com/39UG984.png" preview={false}></Image>
                    </Card>
                </Col>
                <Col md={10} >

                    <Card>
                        <Col md={24} className='mb-3'>
                            <Typography variant="h5">CONTENT</Typography>
                        </Col>
                        <Image src="https://i.imgur.com/ubmZWFc.png" preview={false}></Image>
                    </Card>
                </Col>
                <Col md={14} >
                    <Card>
                        <Col md={24} className='mb-3'>
                            <Typography variant="h5">TOP 5 SELLER</Typography>
                        </Col>
                        <Col md={24} className="table-custom-responsive">
                            <Table className="table-custom" responsive>
                                <thead style={{ background: "#f2f2f2", fontWeight: "bold" }}>
                                    <tr>
                                        <th style={{textAlign:"center" }}>Rank</th>
                                        <th style={{textAlign:"center" }}>Nick Name</th>
                                        <th style={{textAlign:"center" }}>Total order</th>
                                        <th style={{textAlign:"center" }}>Total Sale</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading ? (
                                            <tr>
                                                <td colSpan="10" style={{ textAlign: "center", verticalAlign: "middle", height: "50vh" }}>
                                                    <div style={{ display: "inline-block" }}>
                                                        <HashLoader color="#5f99f8" loading={loading} />
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (

                                            rankSeller.length === 0 ? (
                                                <tr>
                                                    <td colSpan="10" style={{ textAlign: "center" }}><Image preview={false} width={300} src="https://store.vtctelecom.com.vn/Content/images/no-data.png"></Image></td>
                                                </tr>
                                            ) : (
                                                rankSeller.map((sellerRank, index) => (
                                                    <tr key={index} >
                                                        <td style={{ fontWeight:  'bold' , color: '#ff5722',textAlign:"center"  }}>
                                                            {index === 0 && <Image preview={false} src="https://i.imgur.com/mvhcMrJ.png" width="50px" height="50px" />}
                                                            {index === 1 && <Image preview={false} src="https://i.imgur.com/zQMsFDu.png" width="50px" height="50px" />}
                                                            {index === 2 && <Image preview={false} src="https://i.imgur.com/rhN5xFW.png" width="50px" height="50px" />}
                                                            {index > 2 ? index + 1 : ''}
                                                        </td>
                                                        <td style={{textAlign:"center", height:"70px" }} > {sellerRank.sellerName}</td>
                                                        <td style={{textAlign:"center" }}> {sellerRank.totalOrder}</td>
                                                        <td style={{textAlign:"center",color:"red",fontWeight:  'bold' }}> {sellerRank.totalSale.toFixed(2)} $</td>

                                                    </tr>
                                                ))
                                            ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default TopSeller;
