import React, { useEffect, useState } from "react";
import { Typography } from "../../../node_modules/@mui/material/index";
import { Col, Divider, Drawer, Image, Row } from "../../../node_modules/antd/es/index";
import { SearchOutlined } from '@ant-design/icons';
import {
    Input,
    Select,
    DatePicker,
    Modal,
    Table as TableAntd,
    Button,
    message as antdMessage,
    InputNumber,
    Tabs,
    Form
} from 'antd';
import './index.css'
import orderService from "service/order.service";
import Table from "../../../node_modules/react-bootstrap/esm/Table";
import CurrencyConverter from "components/CurrencyConverter";
import PaginationCustom from "components/pagination/PaginationCustom";
import DateFormatConverter from "components/fomartDateTime/DateFormatConverter";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from "../../../node_modules/react-router-dom/dist/index";
import HashLoader from "../../../node_modules/react-spinners/HashLoader";
import dayjs from 'dayjs';

const { TextArea } = Input;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const AllOrder = () => {
    const navigate = useNavigate();

    const format = new CurrencyConverter()
    const fomartDate = new DateFormatConverter();
    const [listOrder, setListOrder] = useState([]);
    const [searchOrder, setSearchOrder] = useState({
        endDate: null,
        fulfillment: null,
        idOrderDetail: null,
        page: 0,
        size: 10,
        startDate: null,
        status: null,
        dispute: null,
        exportOrder: null,
        nameSeller: null,
        emailCustomer: null,
        nameCustomer: null,
        tracking: null
    })
    const userAdmin = JSON.parse(localStorage.getItem('userAdmin'));

    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [excelImport, setExcelImport] = useState([])
    const [loading, setLoading] = useState(false)
    const [modalVisible, setModalVisible] = useState(false);

    const [orderDetail, setOrderDetail] = useState({})
    useEffect(() => {
        findAllOrder()
    }, [searchOrder]);

    const [selectSub, setSelectSub] = useState("")

    const handleRowClick = (record) => {
        setOrderDetail(record)

        showDrawer();

    };
    const showDrawer = () => {
        setIsDrawerVisible(true);
    };

    const findAllOrder = () => {
        setLoading(true);

        return orderService.findAllBill(searchOrder)
            .then(res => {
                setListOrder(res.data.data.content);
                setTotalPages(res.data.data.totalPages);
                setLoading(false);
            }).catch((err) => {
                if (err.response.status === 401) {
                    navigate("/login")
                }
            })
    }

    const handlePageChange = (page) => {
        setSearchOrder({ ...searchOrder, page: page - 1 })
        setCurrentPage(page)
    };


    function formatDateTime() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        return `${year}${month}${day}_${hours}${minutes}${seconds}`;
    }


    const confirmExport = (idPixel) => {
        if (searchOrder.exportOrder !== false) {
            antdMessage.error("Only export orders that have not yet been exported")
            return
        }
        if (selectSub === null || selectSub === "") {
            antdMessage.warning("Please select sub");
            return;
        }
        if (idPixel) {
            confirmAlert({
                message:
                    `Are you sure you want to export the order? If exported, the order may be lost !`,
                buttons: [
                    {
                        label: "Return",
                        className: "stayPage",
                    },
                    {
                        label: "Confirm",
                        onClick: () => handleExport(),
                        className: "leavePage",
                    },
                ],
            });
        }
    };

    const columnTitles = [
        { header: "Order number", key: "idOrderDetail" },
        { header: "Product Name", key: "nameProduct" },
        { header: "Product information（such as color,size and others", key: "nameTemplateCb" },
        { header: "Image URL", key: "imageCombo" },
        { header: "Quantity", key: "quantity" },
        { header: "Customer name", key: "fullName" },
        { header: "Customer Address", key: "address" },
        { header: "Create Date", key: "createDate" },
        { header: "Email Address", key: "emailAddress" },
    ];

    const columnTitlesUsaDrop = [
        { header: "Order number", key: "idOrderDetail" },
        { header: "Variant", key: "nameTemplateCb" },
        { header: "Quantity", key: "quantity" },
        { header: "Product Name", key: "nameProduct" },
        { header: "Image URL", key: "imageCombo" },
        { header: "Customer name", key: "fullName" },
        { header: "Shipping Street", key: "addressLine1" },
        { header: "Shipping Address1", key: "address" },
        { header: "Shipping City", key: "adminArea2" },
        { header: "Shipping Code", key: "postalCode" },
        { header: "Shipping Province", key: "adminArea1" },
        { header: "Shipping Country", key: "countryCode" },
        { header: "Shipping Phone", key: "phoneNumber" },
        { header: "Create Date", key: "createDate" },
        { header: "Email Address", key: "emailAddress" },

    ];
    const handleExport = () => {
        if (selectSub === 1) {
            orderService.exportExelFile(searchOrder)
                .then(res => {
                    const data = res.data.data;

                    const combinedData = data.map(item => {
                        let address = `${item.addressLine1}, ${item.adminArea2}`;
                        if (item.adminArea1 !== "N/A") {
                            address += `, ${item.adminArea1}`;
                        }
                        if (item.postalCode) {
                            address += `, ${item.postalCode}`;
                        }
                        if (item.countryCode) {
                            address += `, ${item.countryCode}`;
                        }
                        return { ...item, address: address };
                    });

                    const filteredData = combinedData.map(item => {
                        const filteredItem = {};
                        columnTitles.forEach(column => {
                            filteredItem[column.key] = item[column.key];
                        });
                        return filteredItem;
                    });
                    const wb = XLSX.utils.book_new();
                    const ws = XLSX.utils.json_to_sheet(filteredData, {
                        header: columnTitles.map(col => col.key),
                    });

                    XLSX.utils.sheet_add_aoa(ws, [columnTitles.map(col => col.header)], { origin: "A1" });

                    XLSX.utils.book_append_sheet(wb, ws, 'Orders');

                    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

                    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

                    const dateTimeString = formatDateTime();

                    saveAs(blob, `OrderDetailsAzSeller_${dateTimeString}.xlsx`);
                    findAllOrder();
                })
                .catch(err => {
                    console.log(err);
                });
        }
        if (selectSub === 2) {
            orderService.exportExelFile(searchOrder)
                .then(res => {
                    const data = res.data.data;

                    const combinedData = data.map(item => {
                        let address = `${item.addressLine1}, ${item.adminArea2}`;
                        if (item.adminArea1 !== "N/A") {
                            address += `, ${item.adminArea1}`;
                        }
                        if (item.postalCode) {
                            address += `, ${item.postalCode}`;
                        }
                        if (item.countryCode) {
                            address += `, ${item.countryCode}`;
                        }
                        return { ...item, address: address };
                    });
                    const filteredData = combinedData.map(item => {
                        const filteredItem = {};
                        columnTitlesUsaDrop.forEach(column => {
                            filteredItem[column.key] = item[column.key];
                        });
                        return filteredItem;
                    });
                    const wb = XLSX.utils.book_new();
                    const ws = XLSX.utils.json_to_sheet(filteredData, {
                        header: columnTitlesUsaDrop.map(col => col.key),
                    });

                    XLSX.utils.sheet_add_aoa(ws, [columnTitlesUsaDrop.map(col => col.header)], { origin: "A1" });

                    XLSX.utils.book_append_sheet(wb, ws, 'Orders');

                    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

                    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

                    const dateTimeString = formatDateTime();

                    saveAs(blob, `OrderDetailsAzSeller_${dateTimeString}.xlsx`);
                    findAllOrder();
                })
                .catch(err => {
                    console.log(err);
                });
        }


    };


    const s2ab = (s) => {
        const buffer = new ArrayBuffer(s.length);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buffer;
    };

    const handleInputChange = (field, value) => {
        const nullValue = value === 'null' ? null : value;

        setSearchOrder((prevSearchOrder) => ({
            ...prevSearchOrder,
            [field]: nullValue,
            page: 0,
            size: 10,
        }));

        setCurrentPage(1)
    };

    const handleRangePickerChange = (dates) => {
        if (dates) {
            const startDate = dates[0] ? dates[0].format('YYYY-MM-DDT00:00:00') : null;
            const endDate = dates[1] ? dates[1].format('YYYY-MM-DDT23:59:59') : null;

            setSearchOrder(prevState => ({
                ...prevState,
                startDate,
                endDate,
            }));
        } else {
            setSearchOrder(prevState => ({
                ...prevState,
                startDate: null,
                endDate: null,
            }));
        }
    };

    const rangePresets = [
        {
            label: 'Yesterday',
            value: [dayjs().add(-1, 'd'), dayjs()],
        },
        {
            label: 'Last 7 Days',
            value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
            label: 'Last 14 Days',
            value: [dayjs().add(-14, 'd'), dayjs()],
        },
        {
            label: 'Last 30 Days',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
            label: 'Last 90 Days',
            value: [dayjs().add(-90, 'd'), dayjs()],
        },
    ];
    const valueGroupMapping = {
        'FULFILLED': 'fulfillment',
        'PARTIALLY_FULFILLED': 'fulfillment',
        'UNFULFILLED': 'fulfillment',
        'PAID': 'status',
        'UNPAID': 'status',
        'REFUND': 'status',
        'Processing': 'dispute',
        'Won': 'dispute',
        'Lost': 'dispute',
    };
    const handleChangeStatus = (selectedValues) => {
        const newState = {
            ...searchOrder,
            fulfillment: [],
            status: [],
            dispute: [],
        };

        selectedValues.forEach(value => {
            const group = valueGroupMapping[value];
            if (group) {
                newState[group] = [...newState[group], value];
            }
        });

        setSearchOrder(newState);
    };

    const closeDrawer = () => {
        setIsDrawerVisible(false);
    };

    let totalFulfillmentPrice;

    try {
        if (!Array.isArray(orderDetail.orderList) || orderDetail.orderList.length === 0) {
            throw new Error("orderList is not an array or empty");
        }
        totalFulfillmentPrice = orderDetail.orderList.reduce((total, item) => {
            if (!item.templateProductEntity || !item.templateComboEntity || typeof item.templateComboEntity.fulfillmentPrice !== 'number' || typeof item.quantity !== 'number') {
                throw new Error("Required fields are missing or invalid");
            }
            return total + (item.templateProductEntity.templateComboEntity.fulfillmentPrice * item.quantity);
        }, 0);
    } catch (error) {
        totalFulfillmentPrice = 0;
    }


    //import excel File tracking

    const checkFileFormat = (jsonData) => {
        if (!jsonData || !jsonData[0] || jsonData[0][0] !== 'trackingNo' || jsonData[0][1] !== 'orderNumber') {
            return false;
        }
        return true;
    };

    const handleImportExcel = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.xlsx';
        input.onchange = (event) => {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                if (!checkFileFormat(jsonData)) {
                    antdMessage.error('Wrong file format or incorrect title');
                    return;
                }

                const trackingAndOrderNumbers = jsonData.slice(1).map(row => {
                    const trackingNo = row[0];
                    const orderNumber = row[1];

                    if (trackingNo !== undefined && orderNumber !== undefined && trackingNo !== null && orderNumber !== null) {
                        return {
                            trackingNo,
                            orderNumber
                        };
                    }

                    return null;
                }).filter(item => item !== null);

                setExcelImport(trackingAndOrderNumbers);
                setModalVisible(true);
            };
            reader.readAsArrayBuffer(file);
        };
        input.click();
    };


    const columnsExecelTracking = [
        {
            title: 'Tracking No',
            dataIndex: 'trackingNo',
            key: 'trackingNo',
        },
        {
            title: 'Order Number',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
        },
    ];

    const handleOk = () => {
        setLoading(true)
        orderService.importExcelTracking(excelImport)
            .then(res => {
                antdMessage.success('Import tracking success');
                findAllOrder();
                setLoading(false)

            }).catch(err => {
                antdMessage.error('Import file error');

                console.log(err);
            })
        setModalVisible(false);
    };

    // refund 

    const [showRefundModal, setShowRefundModal] = useState(false);
    const [formRefund] = Form.useForm();

    const openRefundModal = () => {
        setShowRefundModal(true);
    };

    const closeRefundModal = () => {
        setShowRefundModal(false);
    };


    const handleOkRefund = () => {
        const formData = formRefund.getFieldsValue();
        orderService.refundOrder(formData)
            .then(res => {
                antdMessage.success("Refund success")
                formRefund.resetFields();
                findAllOrder();
                setShowRefundModal(false);
            }).catch(err => {
                antdMessage.error("Refund fail")
            })
    };

    return (
        <>
            <Col md={24} className='mb-3'>
                <Typography variant="h5">All Order</Typography>
            </Col>
            <Row gutter={[16]}>
                <Col md={4}>
                    <Input
                        size="large" placeholder="Order Id"
                        style={{ width: '100%' }}
                        onChange={(e) => handleInputChange('idOrderDetail', e.target.value)}
                        prefix={<SearchOutlined />} />
                </Col>
                <Col md={7}>
                    <RangePicker size="large" style={{ width: '100%' }}
                        presets={rangePresets} onChange={handleRangePickerChange} />
                </Col>
                <Col md={5}>
                    <Select
                        mode="multiple"
                        size={"large"}
                        placeholder="Order Status"
                        onChange={handleChangeStatus}
                        style={{ width: '100%' }}
                        options={[
                            {
                                label: 'Fulfillment status',
                                options: [
                                    { label: 'Fulfilled', value: 'FULFILLED' },
                                    { label: 'Partially Fulfilled', value: 'PARTIALLY_FULFILLED' },
                                    { label: 'Unfulfilled', value: 'UNFULFILLED' },

                                ],
                            },
                            {
                                label: 'Payment status',
                                options: [
                                    { label: 'Paid', value: 'PAID' },
                                    { label: 'Unpaid', value: 'UNPAID' },
                                    { label: 'Refund', value: 'REFUND' },
                                ],
                            },
                            {
                                label: 'Dispute status',
                                options: [
                                    { label: 'Processing', value: 'Processing' },
                                    { label: 'Won', value: 'Won' },
                                    { label: 'Lost', value: 'Lost' },
                                ],
                            },
                        ]} />
                </Col>
                <Col md={4}>
                    <Input
                        size="large" placeholder="Customer Email"
                        style={{ width: '100%' }}
                        onChange={(e) => handleInputChange('emailCustomer', e.target.value)}
                        prefix={<SearchOutlined />} />
                </Col>
            </Row>
            {userAdmin && userAdmin.roles.includes("ROLE_ADMIN") && (

                <Row className="mt-3" gutter={[16]}>
                    <Col md={4}>
                        <Input
                            size="large" placeholder="Customer Name"
                            style={{ width: '100%' }}
                            onChange={(e) => handleInputChange('nameCustomer', e.target.value)}
                            prefix={<SearchOutlined />} />
                    </Col>

                    <Col md={4}>
                        <Input
                            size="large" placeholder="Seller Name"
                            style={{ width: '100%' }}
                            onChange={(e) => handleInputChange('nameSeller', e.target.value)}
                            prefix={<SearchOutlined />} />
                    </Col>
                    <Col md={3}>
                        <Select
                            size={"large"}
                            placeholder="Tracking"
                            style={{ width: "100%" }}
                            onChange={(value) => handleInputChange('tracking', value)}
                            options={[
                                { value: null, label: "All" },
                                { value: false, label: "❌ Not Yet" },
                                { value: true, label: "✅ Already" },
                            ]}
                        />
                    </Col>
                    <Col md={3}>
                        <Select
                            size={"large"}
                            placeholder="Export Order"
                            style={{ width: "100%" }}
                            onChange={(value) => handleInputChange('exportOrder', value)}
                            options={[
                                { value: "", label: "All" },
                                { value: false, label: "❌ Not" },
                                { value: true, label: "✅ Already" },
                            ]}
                        />
                    </Col>

                    <Col md={3}>
                        <Select
                            size={"large"}
                            placeholder="Sub"
                            style={{ width: "100%" }}
                            onChange={(value) => setSelectSub(value)}
                            options={[
                                { value: 1, label: "Mate" },
                                { value: 2, label: "Usadrop" },
                            ]}
                        />
                    </Col>
                    <Col md={3} style={{ textAlign: "end" }}>
                        <Button size="large" type="dashed"
                            style={{ width: "100%", backgroundColor: "#2eb275", color: "white", }}
                            onClick={confirmExport}>Export excel </Button>
                    </Col>
                    <Col md={3} style={{ textAlign: "end" }}>
                        <Button size="large" type="dashed"
                            style={{ width: "100%", backgroundColor: "green", color: "white" }}
                            onClick={handleImportExcel}>Import Tracking </Button>
                    </Col>
                </Row>
            )}
            <Row>
                <Col md={24} className="table-custom-responsive">
                    <Table className="table-custom" responsive>
                        <thead style={{ background: "#f2f2f2", fontWeight: "bold" }}>
                            <tr>
                                <th>#</th>
                                <th>Order Id</th>
                                <th>Time Order</th>
                                <th>Customer</th>
                                <th>Payment</th>
                                <th>Fulfillment</th>
                                <th>Transaction ID</th>
                                <th>Total</th>
                                {userAdmin && userAdmin.roles.includes("ROLE_ADMIN") && (
                                    <>
                                        <th>Seller</th>
                                        <th>Export Date</th>
                                        <th>Tracking</th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>

                            {
                                loading ? (
                                    <tr>
                                        <td colSpan="10"
                                            style={{ textAlign: "center", verticalAlign: "middle", height: "50vh" }}>
                                            <div style={{ display: "inline-block" }}>
                                                <HashLoader color="#5f99f8" loading={loading} />
                                            </div>
                                        </td>
                                    </tr>

                                ) : (
                                    listOrder.length === 0 ? (
                                        <tr>
                                            <td colSpan="10" style={{ textAlign: "center" }}><Image preview={false}
                                                width={300}
                                                src="https://store.vtctelecom.com.vn/Content/images/no-data.png"></Image>
                                            </td>
                                        </tr>
                                    ) : (
                                        listOrder.map((order, index) => (
                                            <tr key={index} onClick={() => handleRowClick(order)}>
                                                <td> {currentPage < 2
                                                    ? index + 1
                                                    : index + 1 + (currentPage - 1) * 10}
                                                </td>
                                                <td>{order.idOrderDetail}</td>

                                                <td>
                                                    <span
                                                        style={{ fontWeight: "bold" }}>{fomartDate.formatDateString(order.createDate)}</span>
                                                    <br />
                                                    <span
                                                        style={{ fontSize: "12px" }}>{fomartDate.formatTimeString(order.createDate)}</span>
                                                </td>
                                                <td><span style={{ fontWeight: "bold" }}>{order.orderPaymentPaypalEntity?.accountPaymentInfoEntity?.fullName}</span> <br />
                                                    <span style={{ fontSize: "12px" }}>{order.orderPaymentPaypalEntity?.accountPaymentInfoEntity?.emailAddress}</span></td>
                                                <td>
                                                    <span style={{
                                                        fontSize: "12px",
                                                        fontWeight: "bold",
                                                        border: "1px solid",
                                                        padding: "5px",
                                                        borderRadius: "5px",
                                                        display: "inline-block",
                                                        color: order.paymentStatus === "PAID" ? "#008000" : "#808080",
                                                        backgroundColor: order.paymentStatus === "PAID" ? "#f0fff0" : "#f0f0f0",
                                                        borderColor: order.paymentStatus === "PAID" ? "#90ee90" : "#d3d3d3"
                                                    }}>{order.paymentStatus}</span>
                                                </td>
                                                <td>
                                                    <span style={{
                                                        fontSize: "12px",
                                                        fontWeight: "bold",
                                                        border: "1px solid",
                                                        padding: "5px",
                                                        borderRadius: "5px",
                                                        display: "inline-block",
                                                        color: order.fulfillment === "FULFILLED" ? "#008000" : order.fulfillment === "UNFULFILLED" ? "white" : "#808080",
                                                        backgroundColor: order.fulfillment === "FULFILLED" ? "#f0fff0" : order.fulfillment === "UNFULFILLED" ? "red" : "#f0f0f0",
                                                        borderColor: order.fulfillment === "FULFILLED" ? "#90ee90" : order.fulfillment === "UNFULFILLED" ? "red" : "#d3d3d3"
                                                    }}>{order.fulfillment}</span>
                                                </td>
                                                <td>{order.orderPaymentPaypalEntity?.paymentCapturePaypalEntity?.captureId}</td>
                                                <td style={{ fontWeight: "bold" }}>${order.totalAmount.toFixed(2)}</td>
                                                {userAdmin && userAdmin.roles.includes("ROLE_ADMIN") && (
                                                    <>
                                                        <td style={{ fontSize: "14px" }}>{order.sellerEntity.nameSeller}</td>
                                                        <td style={{ textAlign: "center" }}>{order.exportOrder === true ? fomartDate.formatDateString(order.exportDate) || "" : "❌"}</td>
                                                        <td style={{ textAlign: "center" }}>{order.trackingId !== null ? "✅" : "❌"}</td>
                                                    </>
                                                )}


                                            </tr>
                                        )))
                                )}

                        </tbody>
                    </Table>

                </Col>
                <Col md={24}>
                    <PaginationCustom
                        currentPageP={currentPage}
                        maxPageNumber={5}
                        total={totalPages}
                        onChange={handlePageChange}
                    />
                </Col>
            </Row>
            <Drawer
                title="Your shopping cart"
                placement="right"
                width={700}
                closable={true}
                onClose={closeDrawer}
                open={isDrawerVisible}
            >
                <Row>
                    <Col md={21}>
                        <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                            #{orderDetail.idOrderDetail ? orderDetail.idOrderDetail : "null"}
                        </span>
                    </Col>
                    <Col md={3}>
                        <span style={{ fontSize: "20px" }}>Total</span>
                    </Col>
                    <Col md={21}>
                        <span style={{
                            fontSize: "10x",
                            color: "#8e9bb7"
                        }}>{fomartDate.formatDateString(orderDetail.createDate)}</span>
                    </Col>
                    <Col md={3}>
                        {orderDetail.refundOrderEntity && orderDetail.refundOrderEntity.amountRefund
                            ? <span style={{ fontSize: "25px", fontWeight: "bold" }}>${orderDetail.totalAmount - orderDetail.refundOrderEntity.amountRefund}</span>
                            : <span style={{ fontSize: "25px", fontWeight: "bold" }}>${orderDetail.totalAmount}</span>}
                    </Col>
                    <Col md={2}>
                        <span style={{
                            fontSize: "10px",
                            color: "#008000",
                            fontWeight: "bold",
                            border: "1px solid #90ee90",
                            padding: "5px",
                            borderRadius: "5px",
                            display: "inline-block",
                            backgroundColor: "#f0fff0",
                        }}>{orderDetail.paymentStatus}</span>
                    </Col>
                    <Col md={4}>
                        <span style={{
                            fontSize: "10px",
                            fontWeight: "bold",
                            border: "1px solid #90ee90",
                            padding: "5px",
                            borderRadius: "5px",
                            display: "inline-block",
                            color: orderDetail.fulfillment === "FULFILLED" ? "#008000" : orderDetail.fulfillment === "UNFULFILLED" ? "white" : "#808080",
                            backgroundColor: orderDetail.fulfillment === "FULFILLED" ? "#f0fff0" : orderDetail.fulfillment === "UNFULFILLED" ? "red" : "#f0f0f0",
                            borderColor: orderDetail.fulfillment === "FULFILLED" ? "#90ee90" : orderDetail.fulfillment === "UNFULFILLED" ? "red" : "#d3d3d3"
                        }}>{orderDetail.fulfillment}</span>
                    </Col>
                    {(orderDetail.fulfillment === "FULFILLED" || orderDetail.fulfillment === "UNFULFILLED") && (
                        <Col md={24} style={{ textAlign: "end" }}>
                            <Button danger onClick={openRefundModal}>Refund</Button>
                        </Col>
                    )}

                    <Modal
                        title="Refund"
                        centered
                        visible={showRefundModal}
                        onCancel={closeRefundModal}
                        footer={null}
                    >
                        <Form
                            name="comboForm"
                            layout="vertical" // Chỉ định kiểu layout cho form
                            onFinish={handleOkRefund}
                            form={formRefund} // Sử dụng biến tham chiếu form
                            initialValues={{ idOrder: orderDetail.idOrderDetail, amountRefund: orderDetail.totalAmount }}
                        >
                            <Form.Item
                                label="Order ID"
                                name="idOrder"
                            >
                                <Input defaultValue={orderDetail.idOrderDetail} readOnly />
                            </Form.Item>
                            <Form.Item
                                label="Amount Refund"
                                name="amountRefund"
                                rules={[{
                                    required: true,
                                    type: 'number',
                                    min: 0,
                                    message: 'Please input the Amount Refund!'
                                }]}
                            >
                                <InputNumber min={0} style={{ width: '100%' }} defaultValue={orderDetail.totalAmount} />

                            </Form.Item>
                            <Form.Item
                                label="Description refund"
                                name="description"
                                rules={[{ required: true, message: 'Please input the Description refund!' }]}
                            >
                                <TextArea style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item style={{ textAlign: "end" }}>
                                <Button type="primary" htmlType="submit">Refund</Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Col md={24}>
                        <Tabs defaultActiveKey="1" className="centered-tabs">
                            <TabPane tab="Original Order" key="1">
                                <Col md={24}>
                                    <span style={{ fontSize: "20px" }}>Invoice</span>
                                    <Row gutter={[0, 0]}>
                                        {
                                            orderDetail.orderEntities ? (
                                                orderDetail.orderEntities.map((listOrder, index) => (
                                                    <>
                                                        <React.Fragment key={index}>
                                                            <Col xs={1} md={0} />
                                                            <Col xs={6} md={5}>
                                                                {
                                                                    listOrder.templateComboEntity.imageCombo ? (
                                                                        <a target="_blank"
                                                                            // href={process.env.REACT_APP_URL_PRODUCT 
                                                                            // + "/" + orderDetail.idProduct
                                                                            // }
                                                                            rel="noreferrer">
                                                                            <Image preview={false} width={"100px"}
                                                                                src={listOrder.templateComboEntity.imageCombo}> </Image></a>
                                                                    ) : (
                                                                        <div style={{
                                                                            width: "100px",
                                                                            height: "100px",
                                                                            backgroundColor: "#f0f0f0",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center"
                                                                        }}>
                                                                            No Image
                                                                        </div>
                                                                    )
                                                                }
                                                            </Col>
                                                            <Col xs={16} md={19}>
                                                                <Row gutter={[0, 10]}>
                                                                    <Col xs={12} md={14}>
                                                                        <label style={{ fontSize: "16px" }}>
                                                                            {listOrder.templateSizeEntity !== null ? `${listOrder.templateComboEntity.nameTemplateCB}/${listOrder.templateSizeEntity.nameSize}` : listOrder.templateComboEntity.nameTemplateCB}
                                                                        </label>
                                                                    </Col>
                                                                    <Col xs={12} md={5} style={{ textAlign: "end" }}>
                                                                        <label style={{
                                                                            fontSize: "14px",
                                                                            color: "#8e9bb7"
                                                                        }}> {format.formatCurrency(listOrder.templateComboEntity?.price)} x<label>{listOrder.quantity}</label></label>
                                                                    </Col>
                                                                    <Col xs={12} md={5} style={{ textAlign: "end" }}>
                                                                        <label style={{
                                                                            fontSize: "14px",
                                                                            color: "green"
                                                                        }}> {format.formatCurrency(listOrder.templateComboEntity?.price * listOrder.quantity)}</label>
                                                                    </Col>
                                                                    <Col xs={24} md={24}>
                                                                        <label style={{
                                                                            fontSize: "14px",
                                                                            color: "#8e9bb7"
                                                                        }}> Description</label>
                                                                    </Col>
                                                                    <Col xs={24} md={24}>
                                                                        <Col md={4}>
                                                                            <span style={{
                                                                                fontSize: "10px",
                                                                                fontWeight: "bold",
                                                                                border: "1px solid #90ee90",
                                                                                padding: "5px",
                                                                                borderRadius: "5px",
                                                                                display: "inline-block",
                                                                                color: orderDetail.fulfillment === "FULFILLED" ? "#008000" : orderDetail.fulfillment === "UNFULFILLED" ? "white" : "#808080",
                                                                                backgroundColor: orderDetail.fulfillment === "FULFILLED" ? "#f0fff0" : orderDetail.fulfillment === "UNFULFILLED" ? "red" : "#f0f0f0",
                                                                                borderColor: orderDetail.fulfillment === "FULFILLED" ? "#90ee90" : orderDetail.fulfillment === "UNFULFILLED" ? "red" : "#d3d3d3"
                                                                            }}>{orderDetail.fulfillment}</span>
                                                                        </Col>
                                                                    </Col>
                                                                    <Col xs={24} md={24}>
                                                                        <label
                                                                            style={{ fontSize: "14px" }}>Carrier: </label>
                                                                        <label style={{
                                                                            fontSize: "14px",
                                                                            color: "#8e9bb7"
                                                                        }}>Home Depot </label>{" "}
                                                                        {
                                                                            orderDetail.trackingId ?
                                                                                <a href={"https://t.17track.net/en#nums=" + orderDetail.trackingId}
                                                                                    target="_blank">Track Shipment</a> :
                                                                                <span style={{
                                                                                    cursor: "not-allowed",
                                                                                    color: "red"
                                                                                }}>Track Shipment</span>
                                                                        }
                                                                    </Col>
                                                                    <Col xs={24} md={24}>
                                                                        <label style={{ fontSize: "14px" }}>Item
                                                                            code: </label>
                                                                        <label style={{
                                                                            fontSize: "14px",
                                                                            color: "#8e9bb7"
                                                                        }}> {orderDetail.idOrderDetail ? orderDetail.idOrderDetail : "null"}</label>
                                                                    </Col>
                                                                    <Col xs={24} md={24}>
                                                                        <Divider style={{ margin: '10px 0' }} />
                                                                    </Col>
                                                                </Row>
                                                                <Row>

                                                                </Row>
                                                            </Col>
                                                        </React.Fragment>

                                                    </>
                                                ))
                                            ) : (
                                                <p>No order details available.</p> // Thông báo khi không có orderList
                                            )
                                        }
                                    </Row>

                                    <Row gutter={[0, 8]}>
                                        <Col xs={6} md={5} />
                                        <Col xs={16} md={19}>
                                            <Row>
                                                <Col xs={12} md={12}>
                                                    <label style={{ fontSize: "16px", color: "#8e9bb7" }}> Sub
                                                        total</label>
                                                </Col>
                                                <Col xs={12} md={12} style={{ textAlign: "end" }}>
                                                    <label
                                                        style={{ fontSize: "16px" }}>{format.formatCurrency(orderDetail.totalAmount - orderDetail.shippingFee)} </label>
                                                </Col>
                                                <Col xs={12} md={12}>
                                                    <label style={{ fontSize: "16px", color: "#8e9bb7" }}> Shipping
                                                        fee</label>
                                                </Col>
                                                <Col xs={12} md={12} style={{ textAlign: "end" }}>
                                                    <label
                                                        style={{ fontSize: "16px" }}>{format.formatCurrency(orderDetail.shippingFee)} </label>
                                                </Col>
                                                <Col xs={12} md={12}>
                                                    <label style={{ fontSize: "16px", color: "#8e9bb7" }}> Fulfillment
                                                        Cost</label>
                                                </Col>
                                                <Col xs={12} md={12} style={{ textAlign: "end" }}>
                                                    <label
                                                        style={{ fontSize: "16px" }}>-{format.formatCurrency(totalFulfillmentPrice)} </label>
                                                </Col>
                                                <Col xs={12} md={12}>
                                                    <label style={{ fontSize: "16px", color: "#8e9bb7" }}>Discount</label>
                                                </Col>
                                                <Col xs={12} md={12} style={{ textAlign: "end" }}>
                                                    <label
                                                        style={{ fontSize: "16px" }}>{format.formatCurrency(0)} </label>
                                                </Col>
                                                <Divider style={{ margin: '10px 0' }} />

                                                <Col xs={12} md={12}>
                                                    <label style={{
                                                        fontSize: "16px",
                                                        fontWeight: "bold"
                                                    }}>Profit</label> / <label
                                                        style={{ fontSize: "16px", color: "#8e9bb7" }}>Total</label>
                                                </Col>
                                                <Col xs={12} md={12} style={{ textAlign: "end" }}>
                                                    <label style={{
                                                        fontSize: "18px",
                                                        color: "green",
                                                        fontWeight: "bold"
                                                    }}>{format.formatCurrency(orderDetail.totalAmount - totalFulfillmentPrice)} </label> / <label
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#8e9bb7"
                                                        }}>{orderDetail.totalAmount}</label>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Divider style={{ margin: '10px 0' }} />

                                        <Col md={5}>
                                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Store</span>
                                        </Col>
                                        <Col md={18}>
                                            <Row>
                                                <Col md={4}>
                                                    <Image src="https://cdn-icons-png.flaticon.com/512/6596/6596121.png"
                                                        width={60}></Image>
                                                </Col>
                                                <Col md={12}>
                                                    <Row>
                                                        {orderDetail.sellerEntity ? (
                                                            <React.Fragment>
                                                                <Col md={24}><span style={{
                                                                    fontSize: "19px",
                                                                    fontWeight: "bold"
                                                                }}>{orderDetail.sellerEntity.nameSeller}</span></Col>
                                                                <Col md={24}><span style={{
                                                                    fontSize: "17px",
                                                                    color: "#8e9bb7"
                                                                }}>{orderDetail.sellerEntity.phoneNumber}</span></Col>
                                                            </React.Fragment>
                                                        ) : (
                                                            <Col md={24}><span>User information not found.</span></Col>
                                                        )}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Divider style={{ margin: '10px 0' }} />

                                        <Col md={5}>
                                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Customer</span>
                                        </Col>
                                        <Col md={19}>
                                            <Row>
                                                <Col md={24}> <label style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold"
                                                }}>{orderDetail.fullName} </label></Col>
                                                <Col md={24}>
                                                    <label style={{
                                                        fontSize: "16px",
                                                        color: "#8e9bb7"
                                                    }}>{orderDetail.emailAddress}</label>
                                                </Col>
                                                <Divider style={{ margin: '10px 0' }} />

                                                <Col md={24}>
                                                    <label style={{
                                                        fontSize: "16px",
                                                        color: "#8e9bb7",
                                                        fontWeight: "bold"
                                                    }}>Shipping address</label>
                                                </Col>
                                                <Col md={4}>
                                                    <label style={{ fontSize: "16px", color: "#8e9bb7" }}>Address:</label>
                                                </Col>
                                                <Col md={20}>
                                                    <label style={{ fontSize: "16px" }}>
                                                        {orderDetail?.orderPaymentPaypalEntity?.accountPaymentInfoEntity?.addressLine1 || 'N/A'},
                                                        {orderDetail?.orderPaymentPaypalEntity?.accountPaymentInfoEntity?.adminArea2 || 'N/A'},
                                                        {orderDetail?.orderPaymentPaypalEntity?.accountPaymentInfoEntity?.adminArea1 || 'N/A'},
                                                        {orderDetail?.orderPaymentPaypalEntity?.accountPaymentInfoEntity?.countryCode || 'N/A'}
                                                    </label>
                                                </Col>
                                                <Col md={4}>
                                                    <label style={{ fontSize: "16px", color: "#8e9bb7" }}>Zip code:</label>
                                                </Col>
                                                <Col md={20}>
                                                    <label style={{ fontSize: "16px" }}>
                                                        {orderDetail?.orderPaymentPaypalEntity?.accountPaymentInfoEntity?.postalCode || 'N/A'}
                                                    </label>
                                                </Col>

                                                <Col md={4}>
                                                    <label style={{ fontSize: "16px", color: "#8e9bb7" }}>Phone:</label>
                                                </Col>
                                                <Col md={20}>
                                                    <label style={{ fontSize: "16px" }}>""</label>
                                                </Col>
                                                <Divider style={{ margin: '10px 0' }} />
                                                <Col md={24}>
                                                    <label style={{
                                                        fontSize: "16px",
                                                        color: "#8e9bb7",
                                                        fontWeight: "bold"
                                                    }}>Shipping address</label>
                                                </Col>
                                                <Col md={24}>
                                                    <label style={{ fontSize: "18px" }}>Same as shipping address.</label>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>
                                </Col>
                            </TabPane>
                            <TabPane tab="Refunded History" key="2">
                                {orderDetail.refundOrderEntity ? (
                                    <Row gutter={[14, 14]}>
                                        <Col md={24}>
                                            <label style={{ fontSize: "16px", color: "#8e9bb7", fontWeight: "bold" }}>
                                                {fomartDate.formatDateString(orderDetail.refundOrderEntity.refundDate) + " " + fomartDate.formatTimeString(orderDetail.refundOrderEntity.refundDate)}
                                            </label>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <label style={{ fontSize: "16px", color: "#8e9bb7" }}> </label>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <label style={{ fontSize: "16px", color: "#8e9bb7" }}> Total refund</label>
                                        </Col>
                                        <Col xs={12} md={12} style={{ textAlign: "end" }}>
                                            <label
                                                style={{ fontSize: "16px" }}>{format.formatCurrency(orderDetail.refundOrderEntity.amountRefund)} </label>
                                        </Col>
                                        <Divider style={{ margin: '10px 0' }} />
                                        <Col xs={6} md={6}>
                                            <label style={{ fontSize: "16px", color: "#8e9bb7" }}> </label>
                                        </Col>
                                        <Col xs={18} md={18}>
                                            <label style={{ fontSize: "16px", color: "#8e9bb7" }}> Payment gateway</label>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <label style={{ fontSize: "16px", color: "#8e9bb7" }}> </label>
                                        </Col>
                                        <Col xs={7} md={7}>
                                            <label style={{ fontSize: "16px", color: "#8e9bb7" }}> Refund Transaction
                                                ID</label>
                                        </Col>
                                        <Col xs={11} md={11} style={{ textAlign: "end" }}>
                                            <label style={{ fontSize: "16px" }}> ... </label>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <label style={{ fontSize: "16px", color: "#8e9bb7" }}> </label>
                                        </Col>
                                        <Col xs={18} md={18}>
                                            <label style={{ fontSize: "18px", fontWeight: "bold" }}>Note </label>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <label style={{ fontSize: "16px", color: "#8e9bb7" }}> </label>
                                        </Col>
                                        <Col xs={18} md={18}>
                                            <label style={{ fontSize: "16px" }}> {orderDetail.refundOrderEntity.description} </label>
                                        </Col>
                                    </Row>
                                ) : null}
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </Drawer>

            <Modal
                title="Tracking and Order Numbers"
                open={modalVisible}
                onCancel={() => setModalVisible(false)}
                onOk={handleOk}

                okText="Import Tracking"
            >
                <TableAntd dataSource={excelImport} columns={columnsExecelTracking} />
            </Modal>
        </>
    )
};

export default AllOrder;
