// assets
import { DropboxOutlined,CodeSandboxOutlined } from '@ant-design/icons';
// icons
const icons = {
  
  DropboxOutlined,
  CodeSandboxOutlined
};

const userAdmin = JSON.parse(localStorage.getItem('userAdmin'));

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
const product = {
  id: 'group-product',
  title: 'Product',
  type: 'group',
  children: [
    {
      id: 'myProduct',
      title: 'My Product',
      type: 'item',
      url: '/product/my-product',
      icon: icons.DropboxOutlined,
      breadcrumbs: false
    },
    // {
    //     id: 'requestProduct',
    //     title: 'Request product',
    //     type: 'item',
    //     url: '/product/request-product',
    //     icon: icons.CodeSandboxOutlined,
    //     breadcrumbs: false
    //   },
   
  ]
};

if (userAdmin && userAdmin.roles && !userAdmin.roles.includes('ROLE_ADMIN')) {
  const filteredChildren = product.children.filter(item => item.id !== 'requestProduct');
  product.children = filteredChildren;
}

export default product;
