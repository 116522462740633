// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { useNavigate } from '../node_modules/react-router-dom/dist/index';
import useAuthRedirect from './UseAuthRedirect';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  useAuthRedirect()
 return (
    <ThemeCustomization>
      <ScrollTop>
        <Routes />
      </ScrollTop>
    </ThemeCustomization>
  );
}

export default App;
