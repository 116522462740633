
import axios from 'axios';
import authHeader from './auth-header';
import { json } from '../../node_modules/react-router-dom/dist/index';

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/v1/product/`;
class ProductService {
  findProductByName(nameProduct) {
    return axios.post(API_URL + 'find-by-name', nameProduct, { headers: authHeader() });
  }
  findAllProduct() {
    return axios.get(API_URL + 'seller', { headers: authHeader() });

  }

  productAnalytics(json) {
    return axios.post(API_URL + 'analytics', json, { headers: authHeader() });

  }

  charAnalytics(json) {
    return axios.post(API_URL + 'analytics/chart', json, { headers: authHeader() });

  }


  findShippingFee() {
    return axios.get(API_URL + 'shipping-fee', { headers: authHeader() })
  }

  updateShippingFee(fee) {
    return axios.get(API_URL + 'update/shipping-fee/' + fee, { headers: authHeader() })
  }

  createProduct(json) {
    return axios.post(API_URL + 'create-product', json, { headers: authHeader() });

  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new ProductService();
