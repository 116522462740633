import { Card, Row, Col, Typography, Button, Progress } from 'antd';

const { Title, Text } = Typography;

const Home = () => {
    return (
        <div style={{ height: '100vh', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
            <Row justify="center" style={{ width: '100%' }}>
                <Col xs={24} sm={22} md={20} lg={18} xl={16}>
                    <Card style={{ padding: '20px', minHeight: '300px' }}>
                        <Title level={4}>Setup Guide</Title>
                        <Text>Let's get all the processing steps finished to increase the first impression to your customers.</Text>
                        <Progress percent={33} status="active" />
                        <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                            <Col span={24}>
                                <Card bordered={false} style={{ backgroundColor: '#f0f2f5' }}>
                                    <Row align="middle" gutter={[16, 16]}>  
                                        <Col span={18}>
                                            <Title level={5}>1. Activate your first product</Title>
                                            <Text>
                                                Let's check out and view our default product list, then activate your favorite one.
                                            </Text>
                                            <Button type="primary" style={{ marginTop: '10px', display: 'block' }}>Activate Product</Button>
                                        </Col>
                                        <Col span={6}>
                                            <img src="https://i.imgur.com/mdIO86n.jpeg" alt="Activate Product" style={{ width: '100%' }} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card bordered={false} style={{ backgroundColor: '#f0f2f5' }}>
                                    <Title level={5}>2. Check the sample and then start running</Title>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card bordered={false} style={{ backgroundColor: '#f0f2f5' }}>
                                    <Title level={5}>3. Check orders and receive money 🤑🤑🤑</Title>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Home;
